import React, { useState, useEffect, useContext } from "react";

import { Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// My Files
import UserAvatar from "../../../management/UserAvatar";
import { UserContext } from "../../../../context";
import firebase from "../../../../firebase";
import MyFolderIcon from "./MyFolderIcon";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 5,
    },
    white: {
        backgroundColor: "rgb(245, 245, 245)",
        height: 60,
        padding: 5,
        borderRadius: 5,
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: 5,
        height: 60,
        borderRadius: 5,
    },
    grey: {
        backgroundColor: "rgb(210, 210, 210 )",
        padding: 5,
        height: 60,
        borderRadius: 5,
    },
    label: {
        textAlign: "left",
        cursor: "pointer",
        // marginTop: 3,
        // marginLeft: 5,
        marginTop: 17,
        marginLeft: 15,
        fontSize: 16,
        width: "90%",
        overflowWrap: "break-word",
        maxWidth: "90%",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    folderIcon: {
        fontSize: 30,
        padding: 0,
        marginLeft: 5,
    },
    arrowIcon: {
        fontSize: 10,
    },
}));

export default function FolderListItem(props) {
    const classes = useStyles();
    const history = useHistory();

    const [clickLocation, setClickLocation] = useState({
        mouseX: null,
        mouseY: null,
    });

    const { currentUserData } = useContext(UserContext);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);
    const [folderNameDialogOpen, setFolderNameDialogOpen] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");
    const [isHub, setIsHub] = useState(false);

    const [isSelected, setIsSelected] = useState(false); // Folder is selected when user right clicks on it

    const [userIsAdmin, setUserIsAdmin] = useState(false);

    useEffect(() => {
        if (props.folder.hub) {
            setIsHub(true);
        } else {
            setIsHub(false);
        }

        if (currentUserData) {
            if (
                props.folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager" ||
                props.folder.createdBy === currentUserData.key
            ) {
                setUserIsAdmin(true);
            } else {
                setUserIsAdmin(false);
            }
        }

        setNewFolderName(props.folder.name);
    }, [props, currentUserData]);

    function handleClick() {
        props.handleClick(props.index);
    }

    const handleRightClick = (event) => {
        event.preventDefault();

        // Checks that the current user is a manager for the folder or hub
        if (userIsAdmin) {
            setClickLocation({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });

            setIsSelected(true);
        }
    };

    const handleMenuClose = (event) => {
        if (event.target.id === "renamefolder") {
            handleFolderRenameDialogOpen();
        } else if (event.target.id === "deletefolder") {
            handleConfirmDeleteDialogOpen();
        } else if (event.target.id === "editusers") {
            // handleEditUsersDialogOpen();
        } else if (event.target.id === "edithub") {
            history.push("/managehub?key=" + props.folder.key);
        }

        setClickLocation({
            mouseX: null,
            mouseY: null,
        });

        setIsSelected(false);
    };

    const handleFolderRenameDialogOpen = () => {
        setFolderNameDialogOpen(true);
    };

    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true);
    };

    const handleFolderRenameDialogClose = (key) => () => {
        setFolderNameDialogOpen(false);

        if (key === "OK") {
            let ref = firebase
                .database()
                .ref("folders")
                .child(props.folder.key);

            ref.update({ name: newFolderName });
        } else {
            setNewFolderName(props.folder.name);
        }
    };

    const handleConfirmDeleteDialogClose = (key) => () => {
        setConfirmDeleteDialogOpen(false);

        if (key === "OK") {
            props.deleteFolder(props.folder);
        }
    };

    const handleKeyboardInput = (e) => {
        setNewFolderName(e.target.value);
    };

    return (
        <Draggable
            draggableId={props.folder.id}
            index={props.index}
            isDragDisabled={
                currentUserData.role === "Player" ||
                currentUserData.role === "FAW Player" ||
                currentUserData.role === "Club Player" ||
                currentUserData.role === "Coach Ed Student" ||
                currentUserData.role === "FAW Coach Ed Student" ||
                currentUserData.role === "Club Coach Ed Student" ||
                currentUserData.role === "FAW Parent" ||
                currentUserData.role === "Club Parent" ||
                currentUserData.role === "Referee"
            }
        >
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classes.container}
                    ref={provided.innerRef}
                    onContextMenu={handleRightClick}
                >
                    {props.green && (
                        <ListItem
                            key={props.folder.id}
                            className={classes.green}
                            onClick={handleClick}
                            style={
                                isSelected ? { border: "2px solid black" } : {}
                            }
                        >
                            {props.folder.hub ? (
                                <UserAvatar
                                    userOrHub={props.folder}
                                    path="hubs"
                                    variant="rounded"
                                />
                            ) : (
                                <MyFolderIcon
                                    className={classes.folderIcon}
                                    folderKey={props.folder.key}
                                />
                            )}
                            <p className={classes.label}>{props.folder.name}</p>
                            {/* <p className={classes.label}>{props.folder.id}</p> */}
                            <ArrowForwardIosIcon
                                className={classes.arrowIcon}
                            />
                        </ListItem>
                    )}

                    {props.grey && (
                        <ListItem
                            key={props.folder.id}
                            className={classes.grey}
                            onClick={handleClick}
                            style={
                                isSelected
                                    ? { border: "2px solid rgb(0, 149, 61)" }
                                    : {}
                            }
                        >
                            {props.folder.hub ? (
                                <UserAvatar
                                    userOrHub={props.folder}
                                    path="hubs"
                                    variant="rounded"
                                />
                            ) : (
                                <MyFolderIcon
                                    className={classes.folderIcon}
                                    folderKey={props.folder.key}
                                />
                            )}
                            <p className={classes.label}>{props.folder.name}</p>
                            {/* <p className={classes.label}>{props.folder.id}</p> */}
                            <ArrowForwardIosIcon
                                className={classes.arrowIcon}
                            />
                        </ListItem>
                    )}

                    {props.white && (
                        <ListItem
                            key={props.folder.id}
                            className={classes.white}
                            onClick={handleClick}
                            style={
                                isSelected
                                    ? { border: "2px solid rgb(0, 149, 61)" }
                                    : {}
                            }
                        >
                            {props.folder.hub ? (
                                <UserAvatar
                                    userOrHub={props.folder}
                                    path="hubs"
                                    variant="rounded"
                                />
                            ) : (
                                <MyFolderIcon
                                    className={classes.folderIcon}
                                    folderKey={props.folder.key}
                                />
                            )}
                            <p className={classes.label}>{props.folder.name}</p>
                            {/* <p className={classes.label}>{props.folder.id}</p> */}
                            <ArrowForwardIosIcon
                                className={classes.arrowIcon}
                            />
                        </ListItem>
                    )}
                    <>
                        <Menu
                            keepMounted
                            open={clickLocation.mouseY !== null}
                            onClose={handleMenuClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                clickLocation.mouseY !== null &&
                                clickLocation.mouseX !== null
                                    ? {
                                          top: clickLocation.mouseY,
                                          left: clickLocation.mouseX,
                                      }
                                    : undefined
                            }
                        >
                            {isHub ? (
                                <div>
                                    <MenuItem
                                        id="edithub"
                                        onClick={handleMenuClose}
                                    >
                                        Edit Hub
                                    </MenuItem>
                                </div>
                            ) : (
                                <div>
                                    <MenuItem
                                        id="renamefolder"
                                        onClick={handleMenuClose}
                                    >
                                        Rename Folder
                                    </MenuItem>
                                    <MenuItem
                                        id="deletefolder"
                                        onClick={handleMenuClose}
                                    >
                                        Delete Folder
                                    </MenuItem>
                                </div>
                            )}
                        </Menu>
                        <Dialog
                            open={folderNameDialogOpen}
                            onClose={handleFolderRenameDialogClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                Rename Folder
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Enter a new name for the folder.
                                </DialogContentText>
                                <TextField
                                    value={newFolderName}
                                    onChange={handleKeyboardInput}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label=""
                                    type=""
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleFolderRenameDialogClose(
                                        "Cancel"
                                    )}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleFolderRenameDialogClose(
                                        "OK"
                                    )}
                                    color="primary"
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={confirmDeleteDialogOpen}
                            onClose={handleConfirmDeleteDialogClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                Delete Folder
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete this folder?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleConfirmDeleteDialogClose(
                                        "Cancel"
                                    )}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleConfirmDeleteDialogClose(
                                        "OK"
                                    )}
                                    color="primary"
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                </div>
            )}
        </Draggable>
    );
}
