import { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

// My Files
import { DatabaseContext, UserContext } from "../../context";

import "./Calendar.css";
import ToolbarWithLogo from "../ToolbarWithLogo";
import FloatingAddButton from "../FloatingAddButton";
import NewEventDialog from "./NewEventDialog";
import MoreEventsDialog from "./MoreEventsDialog";
import firebase from "../../firebase";
import CalendarComponent from "./CalendarComponent";
import ViewEventDialog from "./ViewEventDialog";
import NoPermissionsMessage from "../NoPermissionsMessage";
import { makeid } from "../../utility";

const useStyles = makeStyles((theme) => ({
    calendarContainer: {
        padding: 10,
        paddingBottom: 100,
    },
    dropdownMenu: {
        margin: 15,
        maxWidth: window.innerWidth - 25,
    },
}));

export default function CalendarPage() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [newEventDialogOpen, setNewEventDialogOpen] = useState(false);
    const [viewEventDialogOpen, setViewEventDialogOpen] = useState(false);
    const [moreEventsDialogOpen, setMoreEventsDialogOpen] = useState(false);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedMoreEvents, setSelectedMoreEvents] = useState([]);

    const [userCanView, setUserCanView] = useState();
    const [userCanEdit, setUserCanEdit] = useState(false);

    const { currentUserData } = useContext(UserContext);

    const { calendars, events, folders } = useContext(DatabaseContext);

    const [availableEvents, setAvailableEvents] = useState([]);

    const [selectedCalendars, setSelectedCalendars] = useState();
    const [availableCalendars, setAvailableCalendars] = useState();

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        console.log("useEffect:CalendarPage");

        if (currentUserData && currentUserData.permissions) {
            if (currentUserData.permissions.viewCalendar) {
                setUserCanView(true);

                let calendarsArray = [];
                let eventsArray = [];

                calendars.forEach((calendar) => {
                    let shouldAddCalendar = false;

                    // User should be able to see calendar if it's their personal one, or if they are a user or manager
                    // Of the hub for the calendar
                    if (calendar.user === currentUserData.key) {
                        shouldAddCalendar = true;
                    } else {
                        let hub = folders.filter(
                            (f) => f.key === calendar.hub
                        )[0];

                        if (hub) {
                            if (
                                hub.users.includes(currentUserData.key) ||
                                hub.managers.includes(currentUserData.key)
                            ) {
                                shouldAddCalendar = true;
                            }
                        }
                    }

                    if (shouldAddCalendar) {
                        calendarsArray.push(calendar);
                        let matchingEvents = events.filter(
                            (e) => e.calendar === calendar.key
                        );

                        matchingEvents.forEach((event) => {
                            eventsArray.push(event);

                            if (selectedCalendars) {
                                event.visible =
                                    selectedCalendars.filter(
                                        (c) => c.key === calendar.key
                                    ).length > 0;
                            } else {
                                event.visible = true;
                            }
                        });
                    }
                });

                setAvailableEvents(eventsArray);

                if (!selectedCalendars) {
                    setSelectedCalendars(calendarsArray);
                    setAvailableCalendars(calendarsArray);
                }
            } else {
                setUserCanView(false);
            }

            if (currentUserData.permissions.editCalendar) {
                setUserCanEdit(true);
            }
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserData, history, events, calendars, folders]);

    function handleClickOpenNewEventDialog() {
        setNewEventDialogOpen(true);
    }

    function handleCloseNewEventDialog() {
        setNewEventDialogOpen(false);
    }

    function handleCloseViewEventDialog() {
        setViewEventDialogOpen(false);
    }

    function handleCloseMoreEventsDialog() {
        setMoreEventsDialogOpen(false);
    }

    const handleEventClick = (event) => () => {
        setSelectedEvent(event);
        setViewEventDialogOpen(true);
    };

    const handleMoreEventsClick = (moreEvents) => () => {
        setSelectedMoreEvents(moreEvents);
        setMoreEventsDialogOpen(true);
    };

    function handleDateSelect(date) {
        setSelectedDate(date);
    }

    function addEvent(name, selectedCalendar, color) {
        var eventsRef = firebase.database().ref("events");

        let key = "-" + makeid(20);

        eventsRef.child(key).set({
            date: dayjs(selectedDate).toString(),
            name: name,
            calendar: selectedCalendar.key,
            color: color.replace("#", ""),
        });

        var calendarEventsRef = firebase
            .database()
            .ref("calendars/" + selectedCalendar.key + "/events/");

        calendarEventsRef.child(key).set(true);

        setNewEventDialogOpen(false);
    }

    const handleChangeCalendarSelection = (event) => {
        let eventsArray = [];
        let calendarsArray = [];

        event.target.value.forEach((c) => {
            let calendar = availableCalendars.filter(
                (cal) => c.key === cal.key
            )[0];

            if (calendar) {
                calendarsArray.push(calendar);

                // let matchingEvents = events.filter(
                //     (e) => e.calendar === calendar.key
                // );
            }
        });

        // Calendars array now contains all the visible calendars, refresh the events for these

        calendarsArray.forEach((calendar) => {
            let matchingEvents = events.filter(
                (e) => e.calendar === calendar.key
            );

            matchingEvents.forEach((e) => {
                e.visible = true;
                eventsArray.push(e);
            });
        });

        setAvailableEvents(eventsArray);

        setSelectedCalendars(calendarsArray);
    };

    function doSelectedCalendarsContainCalendar(calendar) {
        if (
            selectedCalendars.filter((c) => c.key === calendar.key).length > 0
        ) {
            return true;
        }

        return false;
    }

    return (
        <>
            {userCanView && (
                <>
                    <FormControl className={classes.dropdownMenu}>
                        <Select
                            multiple
                            value={selectedCalendars}
                            onChange={handleChangeCalendarSelection}
                            input={<Input />}
                            renderValue={(selected) =>
                                selected.map((x) => x.name).join(", ")
                            }
                        >
                            {availableCalendars.map((calendar) => (
                                <MenuItem key={calendar.key} value={calendar}>
                                    <Checkbox
                                        checked={doSelectedCalendarsContainCalendar(
                                            calendar
                                        )}
                                        color={"primary"}
                                    />
                                    <ListItemText primary={calendar.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className={classes.calendarContainer}>
                        <CalendarComponent
                            handleEventClick={handleEventClick}
                            handleDateSelect={handleDateSelect}
                            handleMoreEventsClick={handleMoreEventsClick}
                            events={availableEvents.filter((e) => e.visible)}
                        />
                    </div>
                    {userCanEdit && (
                        <FloatingAddButton
                            onClick={handleClickOpenNewEventDialog}
                        />
                    )}

                    <NewEventDialog
                        open={newEventDialogOpen}
                        onClose={handleCloseNewEventDialog}
                        date={selectedDate}
                        addEvent={addEvent}
                        calendars={availableCalendars}
                    />
                    <ViewEventDialog
                        open={viewEventDialogOpen}
                        onClose={handleCloseViewEventDialog}
                        event={selectedEvent}
                    />
                    <MoreEventsDialog
                        open={moreEventsDialogOpen}
                        onClose={handleCloseMoreEventsDialog}
                        events={selectedMoreEvents}
                        handleEventClick={handleEventClick}
                    />
                </>
            )}
            {userCanView === false && <NoPermissionsMessage />}
            <ToolbarWithLogo />
        </>
    );
}
