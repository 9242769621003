import React, { useEffect, useContext, useState, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";

// import ReactHlsPlayer from "react-hls-player";
import ReactPlayer from "react-player";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography, Tooltip } from "@material-ui/core";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import List from "@material-ui/core/List";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import BackButton from "../BackButton";
import firebase from "../../firebase";
import NoPermissionsMessage from "../NoPermissionsMessage";
import { shouldFileBeVisible, shouldPlaylistBeVisible } from "../../utility";
import ToolbarWithLogo from "../ToolbarWithLogo";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import DialogWithTextInput from "../DialogWithTextInput";
import KeyDownHandler from "../KeyDownHandler";
import SharePlaylistDialog from "./SharePlaylistDialog";
import CommentSection from "../files/viewing/CommentSection";
import PlaylistListItem from "./PlaylistListItem";
import PlaylistFilterDialog from "./PlaylistFilterDialog";

const useStyles = makeStyles((theme) => ({
    videoPlayer: {
        marginTop: 0,
        [theme.breakpoints.down("sm")]: { marginTop: 0 },
    },
    videoColumn: {
        [theme.breakpoints.down("sm")]: { paddingLeft: 0 },
        paddingRight: 0,
    },
    listColumn: {
        padding: 0,
    },
    eventList: {
        paddingTop: 0,
        paddingLeft: 10,
        paddingRight: 10,
        // minHeight: window.innerHeight,
        maxHeight: window.innerHeight - 180.0,
        overflow: "scroll",
    },
    pageContainer: {
        paddingBottom: 65,
    },
    videoContainer: {
        position: "relative",
        paddingTop: "56.25%",
        marginTop: 10,
    },
    displayName: {
        flexGrow: 1,
        paddingTop: 10,
        fontSize: 24,
        [theme.breakpoints.down("sm")]: { paddingLeft: 15, fontSize: 19 },
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        textOverflow: "ellipsis",
    },
    titleRow: {
        marginRight: 0,
    },
    starIcon: {
        color: theme.palette.primary.main,
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
    },
    reorderButton: {
        position: "absolute",
        right: 0,
        left: "auto",
    },
    filterButton: {
        position: "absolute",
        right: 40,
        left: "auto",
    },
    uploadedByText: {
        color: "grey",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function WatchPlaylistPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState(null);

    const { files, groups, users, playlistUsage } = useContext(DatabaseContext);

    const playerRef = React.useRef();

    const { currentUserData } = useContext(UserContext);

    // const [playing, setPlaying] = useState(false);

    const [userHasPermission, setUserHasPermission] = useState();

    const [playing, setPlaying] = useState(false);

    const [playlist, setPlaylist] = useState();
    // const [playlistName, setPlaylistName] = useState("");
    // const [playlistKey, setPlaylistKey] = useState("");
    // const [clips, setClips] = useState([]);

    const [file, setFile] = useState({});

    const [currentClip, setCurrentClip] = useState();

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);

    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusIsVisible, setStatusIsVisible] = useState(false);

    const [isTypingComment, setIsTypingComment] = useState(false);

    const [isReorderingClips, setIsReorderingClips] = useState(false);

    const [userIsPlaylistOwner, setUserIsPlaylistOwner] = useState(false);

    const [filterDialogVisible, setFilterDialogVisible] = useState(false);

    const [ratings, setRatings] = useState([]); // Just use this to preserve whether groups are selected in the filter
    const [groupsAfterFiltering, setGroupsAfterFiltering] = useState([]); // Just use this to preserve whether groups are selected in the filter

    const [createdBy, setCreatedBy] = useState("");
    const [createdDate, setCreatedDate] = useState("");

    const getPlaylist = useCallback(
        async (key) => {
            let playlistRef = firebase.database().ref("playlists").child(key);

            if (!playlist) {
                console.log("Reading Playlist from Firebase");

                playlistRef.on("value", function (snapshot) {
                    let key = snapshot.key;
                    let data = snapshot.val();

                    // console.log(data);
                    let clips = [];

                    if (data.clips) {
                        // Turn messages from a JSON object to an array

                        let clipKeys = Object.keys(data.clips);
                        let clipValues = Object.values(data.clips);

                        for (let i = 0; i < clipKeys.length; i++) {
                            let clipLabels = [];
                            if (clipValues[i].labels) {
                                // Change to Object.values!
                                let labelTextValues = Object.values(
                                    clipValues[i].labels
                                );

                                for (
                                    let i = 0;
                                    i < labelTextValues.length;
                                    i++
                                ) {
                                    clipLabels.push(labelTextValues[i]);
                                }
                            }

                            clipLabels.forEach((label) => {
                                label.filteredOutByLabel = false;
                            });

                            clips.push({
                                key: clipKeys[i],
                                index: clipValues[i].index,
                                inTime: clipValues[i].inTime,
                                outTime: clipValues[i].outTime,
                                name: clipValues[i].name,
                                note: clipValues[i].note,
                                labels: clipLabels,
                                stars: clipValues[i].stars,
                                video: clipValues[i].video,
                                filteredOutByCode: false,
                                filteredOutByRating: false,
                                filteredOutByLabels: false,
                            });
                        }
                    }

                    // Groups
                    let playlistGroups = [];
                    if (data.groups) {
                        let groupKeys = Object.keys(data.groups);

                        for (let i = 0; i < groupKeys.length; i++) {
                            playlistGroups.push(groupKeys[i]);
                        }
                    }

                    // Users
                    let playlistUsers = [];
                    if (data.users) {
                        let userKeys = Object.keys(data.users);

                        for (let i = 0; i < userKeys.length; i++) {
                            playlistUsers.push(userKeys[i]);
                        }
                    }

                    let playlist = {
                        key: key,
                        clips: clips,
                        name: data.name,
                        created: data.created,
                        owner: data.owner,
                        video: data.video,
                        users: playlistUsers,
                        groups: playlistGroups,
                    };

                    setPlaylist(playlist);
                });
            } else {
                console.log("Already Read Playlist");
            }
        },
        [playlist]
    );

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData) {
            const urlParams = new URLSearchParams(location.search);

            const key = urlParams.get("key");

            // let matchingPlaylist = playlists.filter((p) => p.key === key)[0];
            getPlaylist(key);

            if (playlist) {
                if (
                    shouldPlaylistBeVisible(playlist, groups, currentUserData)
                ) {
                    if (playlist.owner === currentUserData.key) {
                        setUserIsPlaylistOwner(true);
                    } else {
                        setUserIsPlaylistOwner(false);
                    }

                    if (playlist.owner) {
                        let ownerUser = users.filter(
                            (u) => u.key === playlist.owner
                        )[0];
                        if (ownerUser) {
                            setCreatedBy(
                                ownerUser.firstName + " " + ownerUser.lastName
                            );
                        }
                    }

                    if (playlist.created) {
                        setCreatedDate(
                            dayjs(playlist.created).format("MMMM D, YYYY")
                        );
                    }
                    // setPlaylistName(matchingPlaylist.name);
                    // setPlaylistKey(matchingPlaylist.key);
                    // setClips(matchingPlaylist.clips);
                    // console.log(matchingPlaylist);

                    let file = files.filter((f) => f.key === playlist.video)[0];

                    // console.log(playlist);

                    if (file) {
                        setFile(file);

                        if (
                            shouldFileBeVisible(file, groups, currentUserData)
                        ) {
                            setUserHasPermission(true);

                            setRatings([
                                { stars: 0, selected: true },
                                { stars: 1, selected: true },
                                { stars: 2, selected: true },
                                { stars: 3, selected: true },
                            ]);

                            // Update most recent opened date for the playlist

                            let playlistUsageRef = firebase
                                .database()
                                .ref("playlistusage");

                            let usageObject = playlistUsage.filter(
                                (u) =>
                                    u.user === currentUserData.key &&
                                    u.playlist === playlist.key
                            )[0];

                            if (usageObject) {
                                // Update the existing usage object
                                playlistUsageRef
                                    .child(usageObject.key)
                                    .update({ lastOpened: dayjs().toString() });
                            } else if (playlistUsage.length > 0) {
                                // Check for playlistUsage > 0 is because this code was being run even when a matching playlistUsage
                                // Object already exists, because the database hadn't been read in yet

                                // First time opening the playlist, create the usage object
                                playlistUsageRef.push({
                                    playlist: playlist.key,
                                    user: currentUserData.key,
                                    lastOpened: dayjs().toString(),
                                });
                            }
                        } else {
                            setUserHasPermission(false);
                        }
                    }
                } else {
                    setUserHasPermission(false);
                }
            }
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [currentUserData, playlist]);

    // Called from KeyDownHandler component
    function keyWasPressed(e) {
        if (
            isTypingComment ||
            shareDialogOpen ||
            renameDialogOpen ||
            confirmDeleteDialogOpen ||
            e.target.nodeName === "VIDEO"
        ) {
            return;
        }
        if (e.code === "Space") {
            e.preventDefault();

            if (playing === true) {
                handlePause();
            } else {
                handlePlay();
            }
        }
    }

    function handlePlay() {
        setPlaying(true);
    }

    function handlePause() {
        setPlaying(false);
    }

    function handleProgress(progress) {
        if (currentClip) {
            if (progress.playedSeconds > currentClip.outTime) {
                // Find next clip that is visible

                let visibleClips = playlist.clips
                    .sort((a, b) => {
                        if (a.index > b.index) return 1;
                        if (a.index < b.index) return -1;
                        return 0;
                    })
                    .filter((clip) => clip.index > currentClip.index);

                for (let index = 0; index < visibleClips.length; index++) {
                    const clip = visibleClips[index];

                    if (shouldShowInTable(clip)) {
                        playerRef.current.seekTo(clip.inTime, "seconds");

                        setCurrentClip(clip);

                        return;
                    }
                }

                // If we reach here, have come to the end of the visible clips, so stop playing
                setPlaying(false);
            }
        }
    }

    function handleSeek(seconds) {
        // let insideClip = false;
        // playlist.clips.forEach((clip, index) => {
        //     if (seconds > clip.inTime && seconds < clip.outTime) {
        //         insideClip = true;
        //         setCurrentClip(clip);
        //         setCurrentClipIndex(index);
        //         setPlaying(true);
        //     }
        // });
        // if (!insideClip) {
        //     setCurrentClip();
        //     // if (seconds < currentClip.inTime || seconds > currentClip.outTime) {
        //     // }
        // }
    }

    function handleClipSelect(index, clip, e) {
        setCurrentClip(clip);
        playerRef.current.seekTo(clip.inTime, "seconds");
        setPlaying(true);
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.target);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function handleRenamePlaylistButtonPress() {
        handleMenuClose();
        setRenameDialogOpen(true);
    }

    function handleDeletePlaylistButtonPress() {
        handleMenuClose();
        setConfirmDeleteDialogOpen(true);
    }

    function handleCloseRenameDialog(newName, okPressed) {
        if (newName !== "" && okPressed) {
            // Rename the playlist in firebase
            let playlistsRef = firebase.database().ref("playlists");
            playlistsRef.child(playlist.key).update({ name: newName });

            let playlistInfoRef = firebase.database().ref("playlistinfo");
            playlistInfoRef.child(playlist.key).update({ name: newName });

            // Rename locally
            setPlaylist((prev) => ({
                ...prev,
                name: newName,
            }));
        }

        setRenameDialogOpen(false);
    }

    function handleCloseDeleteDialog(result) {
        if (result === true && currentUserData) {
            // Delete the timeline from firebase
            let playlistsRef = firebase.database().ref("playlists");

            playlistsRef.child(playlist.key).remove();

            let playlistInfoRef = firebase.database().ref("playlistinfo");

            playlistInfoRef.child(playlist.key).remove();

            // Delete any notifications with the same key

            let notificationsRef = firebase.database().ref("notifications");
            notificationsRef.child(playlist.key).remove();

            history.goBack();
        }

        setConfirmDeleteDialogOpen(false);
    }

    function handleShareButtonPressed() {
        handleMenuClose();

        setShareDialogOpen(true);
    }

    function handleCloseShareDialog(userCount) {
        if (userCount > 0) {
            if (userCount === 1) {
                setStatusMessage("Playlist Shared with 1 User");
            } else {
                setStatusMessage(
                    "Playlist Shared with " + userCount + " Users"
                );
            }

            setStatusType("success");
            setStatusIsVisible(true);
        }
        setShareDialogOpen(false);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function setIsTyping(isTyping) {
        setIsTypingComment(isTyping);
    }

    function handleReorderClipsButtonPress() {
        setPlaying(false);
        setCurrentClip(null);
        setIsReorderingClips(!isReorderingClips);
    }

    function handleMoveClipUpward(clip) {
        if (clip.index === 0) {
            return;
        }

        let oldIndex = clip.index;

        let clipToMove = playlist.clips[oldIndex - 1];
        clipToMove.index = oldIndex;
        clip.index = oldIndex - 1;

        // Update Firebase

        let clipsRef = firebase
            .database()
            .ref("playlists")
            .child(playlist.key)
            .child("clips");

        clipsRef.child(clipToMove.key).update({ index: clipToMove.index });
        clipsRef.child(clip.key).update({ index: clip.index });

        // forceUpdate();
    }

    function handleMoveClipDownward(clip) {
        if (clip.index === playlist.clips.length - 1) {
            return;
        }

        let oldIndex = clip.index;

        let clipToMove = playlist.clips[oldIndex + 1];

        clipToMove.index = oldIndex;
        clip.index = oldIndex + 1;

        // Update Firebase
        let clipsRef = firebase
            .database()
            .ref("playlists")
            .child(playlist.key)
            .child("clips");
        clipsRef.child(clipToMove.key).update({ index: clipToMove.index });
        clipsRef.child(clip.key).update({ index: clip.index });

        // forceUpdate();
    }

    function handleOpenFilterButtonPress() {
        setFilterDialogVisible(true);
    }

    function handleCloseFilterDialog(
        newFilteredCodes,
        newFilteredLabels,
        newFilteredRatings,
        groups
    ) {
        if (newFilteredCodes) {
            playlist.clips.forEach((row) => {
                if (newFilteredCodes.includes(row.name)) {
                    row.filteredOutByCode = false;
                } else {
                    row.filteredOutByCode = true;
                }
            });
        }

        setGroupsAfterFiltering(groups);

        if (newFilteredLabels) {
            playlist.clips.forEach((row) => {
                if (row.labels.length === 0) {
                    if (newFilteredLabels.includes("(No Detail)")) {
                        row.filteredOutByLabels = false;
                    } else {
                        row.filteredOutByLabels = true;
                    }
                } else {
                    row.labels.forEach((label) => {
                        if (newFilteredLabels.includes(label.text)) {
                            label.filteredOutByLabel = false;
                        } else {
                            label.filteredOutByLabel = true;
                        }
                    });
                }
            });
        }

        if (newFilteredRatings) {
            playlist.clips.forEach((row) => {
                if (newFilteredRatings.includes(row.stars)) {
                    row.filteredOutByRating = false;
                } else {
                    row.filteredOutByRating = true;
                }
            });
        }

        // forceUpdate();

        setFilterDialogVisible(false);
    }

    function shouldShowInTable(clip) {
        // console.log("Show?");
        // console.log(row);

        // return true;

        var filteredOut = false;
        if (clip.filteredOutByCode === true) {
            filteredOut = true;
        }

        if (clip.labels.length === 0) {
            if (clip.filteredOutByLabels === true) {
                filteredOut = true;
            }
        } else {
            if (
                clip.labels.filter(
                    (label) => label.filteredOutByLabel === false
                ).length === 0
            ) {
                filteredOut = true;
            }
        }

        if (clip.filteredOutByRating === true) {
            filteredOut = true;
        }

        if (filteredOut === true) {
            return false;
        }

        return true;
    }

    return (
        <>
            {userHasPermission && (
                <>
                    <BackButton />
                    <Tooltip title={"Edit Clip Order"}>
                        <IconButton
                            className={classes.reorderButton}
                            component="span"
                            onClick={handleReorderClipsButtonPress}
                        >
                            <FormatListNumberedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter">
                        <IconButton
                            className={classes.filterButton}
                            component="span"
                            onClick={handleOpenFilterButtonPress}
                            aria-label="openFilterButtonPress"
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                    <Container fluid className={classes.pageContainer}>
                        <Row>
                            <Col
                                className={classes.videoColumn}
                                lg={8}
                                md={12}
                                sm={12}
                            >
                                {file && (
                                    <>
                                        <ReactPlayer
                                            className={classes.videoPlayer}
                                            ref={playerRef}
                                            url={
                                                "https://vz-db0e7359-d70.b-cdn.net/" +
                                                file.key +
                                                "/playlist.m3u8"
                                            }
                                            autoPlay={false}
                                            controls={true}
                                            playing={playing}
                                            onPlay={handlePlay}
                                            onPause={handlePause}
                                            onEnded={handlePause}
                                            onProgress={handleProgress}
                                            onSeek={handleSeek}
                                            width="100%"
                                            height="auto"
                                        />
                                        <Row className={classes.titleRow}>
                                            <Col>
                                                <Typography
                                                    className={
                                                        classes.displayName
                                                    }
                                                >
                                                    {playlist.name}
                                                </Typography>
                                                {createdBy !== "" && (
                                                    <span
                                                        className={
                                                            classes.uploadedByText
                                                        }
                                                    >
                                                        Created By:
                                                        {createdBy}
                                                    </span>
                                                )}
                                                {createdDate !== "" && (
                                                    <span
                                                        className={
                                                            classes.uploadedByText
                                                        }
                                                    >
                                                        {" on " + createdDate}
                                                    </span>
                                                )}
                                            </Col>

                                            <IconButton
                                                onClick={handleMenuOpen}
                                                className={classes.menuButton}
                                                aria-label="moreButtonPress"
                                            >
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </Row>
                                        <Divider />
                                        <CommentSection
                                            file={playlist}
                                            videoPlayer={playerRef}
                                            setIsTyping={setIsTyping}
                                        />
                                    </>
                                )}
                            </Col>
                            <Col
                                className={classes.listColumn}
                                lg={4}
                                md={12}
                                sm={12}
                            >
                                <List className={classes.eventList}>
                                    {playlist.clips
                                        .filter(
                                            (clip) =>
                                                shouldShowInTable(clip) === true
                                        )
                                        .sort((a, b) => {
                                            if (a.index > b.index) return 1;
                                            if (a.index < b.index) return -1;
                                            return 0;
                                        })
                                        .map((clip, index) => (
                                            <div key={clip.key}>
                                                <PlaylistListItem
                                                    clip={clip}
                                                    index={index}
                                                    isReorderingClips={
                                                        isReorderingClips
                                                    }
                                                    currentClip={currentClip}
                                                    handleClipSelect={
                                                        handleClipSelect
                                                    }
                                                    handleMoveClipUpward={
                                                        handleMoveClipUpward
                                                    }
                                                    handleMoveClipDownward={
                                                        handleMoveClipDownward
                                                    }
                                                />
                                                <Divider />
                                            </div>
                                        ))}
                                </List>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
            {userHasPermission === false && <NoPermissionsMessage />}
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleShareButtonPressed}>
                    Share Playlist
                </MenuItem>
                {userIsPlaylistOwner && (
                    <MenuItem onClick={handleRenamePlaylistButtonPress}>
                        Rename Playlist
                    </MenuItem>
                )}
                {userIsPlaylistOwner && (
                    <MenuItem onClick={handleDeletePlaylistButtonPress}>
                        Delete Playlist
                    </MenuItem>
                )}
            </Menu>
            {playlist && (
                <DialogWithTextInput
                    open={renameDialogOpen}
                    onClose={handleCloseRenameDialog}
                    title={"Rename Playlist"}
                    message={"Enter a name for the Playlist."}
                    initialValue={playlist.name}
                />
            )}
            {confirmDeleteDialogOpen && (
                <ConfirmDeleteDialog
                    title="Delete Playlist"
                    message="Are you sure you want to delete this Playlist?"
                    open={confirmDeleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                />
            )}
            {filterDialogVisible && (
                <PlaylistFilterDialog
                    open={filterDialogVisible}
                    onClose={handleCloseFilterDialog}
                    rows={playlist.clips}
                    ratings={ratings}
                    groupsAfterFiltering={groupsAfterFiltering}
                />
            )}
            <SharePlaylistDialog
                open={shareDialogOpen}
                onClose={handleCloseShareDialog}
                playlist={playlist}
                file={file}
            />
            <div>
                <Snackbar
                    open={statusIsVisible}
                    autoHideDuration={6000}
                    onClose={handleStatusClose}
                >
                    <Alert onClose={handleStatusClose} severity={statusType}>
                        {statusMessage}
                    </Alert>
                </Snackbar>
            </div>
            <ToolbarWithLogo />
            <KeyDownHandler keyWasPressed={keyWasPressed} />
        </>
    );
}
