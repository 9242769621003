import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import AvatarUploadButton from "../management/AvatarUploadButton";
import ToolbarWithLogo from "../ToolbarWithLogo";
import FloatingSaveButton from "../FloatingSaveButton";
import firebase from "../../firebase";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ChangeEmailDialog from "./ChangeEmailDialog";
// import {
//     jsonFromArray,
//     jsonFromUserEnteredStringArray,
//     getAncestorHubs,
// } from "../../utility";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 100,
    },
    buttonProgress: {
        color: "rgb(244, 36, 29)",
        position: "fixed",
        zIndex: 1,
        top: "auto",
        bottom: 70,
        left: "auto",
        right: 10,
    },
    inputMargin: {
        margin: theme.spacing(1),
        flex: 1,
    },
    button: {
        marginLeft: 10,
    },
    title: {
        paddingTop: 10,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MyAccountPage() {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();

    const { currentUserData } = useContext(UserContext);

    const [hasChangedAvatar, setHasChangedAvatar] = useState(false);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");

    const [changePasswordDialogIsOpen, setChangePasswordDialogIsOpen] =
        useState(false);

    const [changeEmailDialogIsOpen, setChangeEmailDialogIsOpen] =
        useState(false);

    const [statusMessage, setStatusMessage] = useState("");
    const [statusType, setStatusType] = useState("");
    const [statusMessageIsVisible, setStatusMessageIsVisible] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);

    const { comments, conversations } = useContext(DatabaseContext);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        if (currentUserData) {
            setEmail(currentUserData.email);
            setFirstName(currentUserData.firstName);
            setLastName(currentUserData.lastName);
            setPhone(currentUserData.phone);
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [currentUserData, history]);

    function handleChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "firstName") {
            setFirstName(newValue);
        } else if (event.target.id === "lastName") {
            setLastName(newValue);
        } else if (event.target.id === "phone") {
            setPhone(newValue);
        }
    }

    async function handleSaveClick() {
        let ref = firebase.database().ref("users");

        setIsInProgress(true);
        let key = currentUserData.key;
        if (hasChangedAvatar) {
            await uploadAvatar(key).then((downloadURL) => {
                // Edit user object in database

                let oldFullName =
                    currentUserData.firstName + " " + currentUserData.lastName;

                ref.child(key)
                    .update({
                        firstName: firstName,
                        lastName: lastName,
                        phone: phone,
                        imageURL: downloadURL,
                    })
                    .catch((error) => {
                        let errorCode = error.code;
                        let errorMessage = error.message;
                        console.log(errorCode + ":" + errorMessage);
                        setIsInProgress(false);
                        setStatusMessage(error.message);
                        setStatusType("error");
                        setStatusMessageIsVisible(true);
                    });

                if (oldFullName !== firstName + " " + lastName) {
                    changeNameInCommentsAndConversations();
                }

                setIsInProgress(false);
                history.goBack();
            });
        } else {
            // Edit user object in database

            let oldFullName =
                currentUserData.firstName + " " + currentUserData.lastName;

            ref.child(key)
                .update({
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                })
                .catch((error) => {
                    let errorCode = error.code;
                    let errorMessage = error.message;
                    console.log(errorCode + ":" + errorMessage);
                    setIsInProgress(false);
                    setStatusMessage(error.message);
                    setStatusType("error");
                    setStatusMessageIsVisible(true);
                });

            if (oldFullName !== firstName + " " + lastName) {
                changeNameInCommentsAndConversations();
            }

            setIsInProgress(false);
            history.goBack();
        }
        history.goBack();
    }

    // If the user name was changed, go through thier comments and conversations and change the names there as well.
    function changeNameInCommentsAndConversations() {
        let fullName = firstName + " " + lastName;
        let key = currentUserData.key;

        // Update Comments
        comments.forEach((comment) => {
            if (comment.user === key) {
                let ref = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);

                ref.update({ author: fullName });
            }
        });

        // Update Conversations
        conversations.forEach((conv) => {
            conv.messages.forEach((message) => {
                if (message.user === key) {
                    let ref = firebase
                        .database()
                        .ref("conversations")
                        .child(conv.key)
                        .child("messages")
                        .child(message.key);
                    ref.update({ fullName: fullName });
                }
            });
        });
    }

    function didChangeAvatar() {
        setHasChangedAvatar(true);
    }

    function handleCloseChangePasswordDialog() {
        setChangePasswordDialogIsOpen(false);
    }

    function handleCloseChangeEmailDialog() {
        setChangeEmailDialogIsOpen(false);
    }

    function showPasswordChangeStatus() {
        handleCloseChangePasswordDialog();
        setStatusType("success");
        setStatusMessage("Password Updated");
        setStatusMessageIsVisible(true);
    }

    function showEmailChangeStatus() {
        handleCloseChangeEmailDialog();
        setStatusType("success");
        setStatusMessage("Email Updated");
        setStatusMessageIsVisible(true);
    }

    function handlePressUpdateEmailButton() {
        setChangeEmailDialogIsOpen(true);
    }
    function handlePressUpdatePasswordButton() {
        setChangePasswordDialogIsOpen(true);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusMessageIsVisible(false);
    };

    function uploadAvatar(userKey) {
        return new Promise((resolve, reject) => {
            let storage = firebase.storage();

            currentUserData.avatarEditor
                .getImageScaledToCanvas()
                .toBlob(function (blob) {
                    // Upload the new file
                    let avatarRef = storage
                        .ref()
                        .child("avatars/users/" + userKey + "/avatar.png");

                    avatarRef
                        .put(blob)
                        .then((snapshot) => {
                            // Upload was successful
                            console.log("Upload Successful");
                            currentUserData.avatar = null;
                            avatarRef.getDownloadURL().then((url) => {
                                resolve(url);
                            });
                        })
                        .catch((error) => {
                            let errorCode = error.code;
                            let errorMessage = error.message;
                            console.log(errorCode + ":" + errorMessage);
                            setIsInProgress(false);
                            setStatusMessage(error.message);
                            setStatusType("error");
                            setStatusMessageIsVisible(true);
                            reject();
                        });
                });
        });
    }

    // Utility function for making changes to database
    // function handleDatabaseUpdate() {
    //     // Create All Users Groups for each hub
    //     folders.forEach((folder) => {
    //         if (folder.hub) {
    //             let groupsRef = firebase.database().ref("groups");
    //             // Create All Users Group
    //             groupsRef.push({
    //                 name: "All Users",
    //                 hub: folder.key,
    //                 users: jsonFromArray(folder.users),
    //             });
    //         }
    //     });

    //     // // Remove users array from all files
    //     files.forEach((file) => {
    //         let fileRef = firebase.database().ref("files");

    //         fileRef.child(file.key).child("users").remove();
    //     });

    //     // Remove users array from all folders (not hubs)
    //     folders.forEach((folder) => {
    //         if (!folder.hub) {
    //             let folderRef = firebase.database().ref("folders");

    //             folderRef.child(folder.key).child("users").remove();
    //         }
    //     });

    //     // Remove Groups Array from all hubs
    //     folders.forEach((folder) => {
    //         let folderRef = firebase.database().ref("folders");

    //         folderRef.child(folder.key).child("groups").remove();
    //     });
    // }

    // function updateAllUsersGroups() {
    //     // Update all 'All Users' Groups so they have the same number of users as the hub
    //     folders.forEach((folder) => {
    //         if (folder.hub) {
    //             let allUsersGroup = groups.filter(
    //                 (g) => g.hub === folder.key && g.name === "All Users"
    //             )[0];
    //             if (allUsersGroup) {
    //                 let groupRef = firebase
    //                     .database()
    //                     .ref("groups")
    //                     .child(allUsersGroup.key);

    //                 groupRef.update({ users: jsonFromArray(folder.users) });
    //             }
    //         }
    //     });
    // }

    // function checkAllUsersGroups() {
    //     // Check that all hubs have an 'All Users' Group that has the same number of users as the hub

    //     console.log("Checking Hubs 'All Users' Groups");
    //     folders.forEach((folder) => {
    //         if (folder.hub) {
    //             // console.log("Hub: " + folder.name);
    //             // console.log("Users: " + folder.users.length);

    //             let allUsersGroup = groups.filter(
    //                 (g) => g.hub === folder.key && g.name === "All Users"
    //             )[0];
    //             if (allUsersGroup) {
    //                 if (folder.users.length !== allUsersGroup.users.length) {
    //                     console.log(
    //                         "Hub '" + folder.name + "' doesn't match it's group"
    //                     );
    //                 }
    //                 // console.log(allUsersGroup.users.length);
    //             } else {
    //                 console.log(
    //                     "Hub '" +
    //                         folder.name +
    //                         "' doesn't have an all users group"
    //                 );
    //             }
    //             // console.log("-------");
    //         }
    //     });
    //     console.log("Finished Check");
    // }

    // function checkGroups() {
    //     console.clear();
    //     console.log("Checking Groups");
    //     folders.forEach((folder) => {
    //         if (folder.hub) {
    //             // console.log("Hub: " + folder.name);
    //             // console.log("Users: " + folder.users.length);

    //             let folderGroups = groups.filter(
    //                 (g) => g.hub === folder.key && g.name !== "All Users"
    //             );

    //             folderGroups.forEach((group) => {
    //                 group.users.forEach((user) => {
    //                     if (!folder.users.includes(user.key)) {
    //                         console.log(folder.name + " doesn't include user:");
    //                         console.log(user);
    //                     }
    //                 });
    //             });
    //         }
    //     });
    //     console.log("Finished Check");
    // }

    // function removeFileUsage() {
    //     let ref = firebase.database().ref("fileusage");

    //     ref.remove();
    // }

    // function updateHubs() {
    //     console.clear();
    //     console.log("Updating Hubs");
    //     folders.forEach((folder) => {
    //         if (folder.hub) {
    //             if (folder.imageURL === undefined) {
    //                 let folderRef = firebase
    //                     .database()
    //                     .ref("folders")
    //                     .child(folder.key);

    //                 folderRef.update({ imageURL: "" });
    //             }
    //         }
    //     });
    //     console.log("Finished Check");
    // }

    // function createLastLogin() {
    //     users.forEach((user) => {
    //         let ref = firebase.database().ref("userlogin");
    //         if (!user.lastLogin) {
    //             ref.child(user.key).set({ lastLogin: "never" });
    //         } else {
    //             ref.child(user.key).set({ lastLogin: user.lastLogin });
    //         }
    //         // ref.child(user.key).set
    //     });
    // }

    // function makeHubPublic() {
    //     let hubKey = "RhDWyGntB2ZjyoI1HEj";

    //     let hub = folders.filter((f) => f.key === hubKey)[0];
    //     if (hub) {
    //         let usersObject = jsonFromArray(users);

    //         let ref = firebase.database().ref("folders");
    //         ref.child(hubKey).update({ users: usersObject });

    //         // Add to All Users group for this hub

    //         let group = groups.filter(
    //             (g) => g.hub === hubKey && g.name === "All Users"
    //         )[0];
    //         if (group) {
    //             let groupRef = firebase.database().ref("groups");

    //             groupRef.child(group.key).update({ users: usersObject });
    //         }
    //     }
    // }

    // function updatePermissions() {
    //     let ref = firebase.database().ref("users");

    //     // users.forEach((user) => {
    //     //     let permissionsObject = jsonFromArray(user.permissions);

    //     //     permissionsObject["shareFiles"] = true;
    //     //     console.log(permissionsObject);
    //     //     // ref.child(user.key).child("permissions").update()
    //     // });

    //     users.forEach((user) => {
    //         let permissionsObject = user.permissions;
    //         permissionsObject["shareFiles"] = false;

    //         ref.child(user.key).child("permissions").set(permissionsObject);
    //     });
    // }

    // function updateUserTypes() {
    //     console.clear();
    //     console.log("Updating User Types");

    //     let ref = firebase.database().ref("users");

    //     users.forEach((user) => {
    //         if (user.role === "Analyst") {
    //             ref.child(user.key).update({ role: "FAW Analyst" });
    //         }
    //         if (user.role === "Coach") {
    //             ref.child(user.key).update({ role: "FAW Coach" });
    //         }
    //         if (user.role === "Support Staff") {
    //             ref.child(user.key).update({ role: "FAW Support Staff" });
    //         }
    //         if (user.role === "Coach Educator") {
    //             ref.child(user.key).update({ role: "FAW Coach Educator" });
    //         }
    //         if (user.role === "Coach Ed Student") {
    //             ref.child(user.key).update({ role: "FAW Coach Ed Student" });
    //         }
    //         if (user.role === "Player") {
    //             ref.child(user.key).update({ role: "FAW Player" });
    //         }
    //         if (user.role === "Parent") {
    //             ref.child(user.key).update({ role: "FAW Parent" });
    //         }

    //         if (user.customRoleName === "Analyst") {
    //             ref.child(user.key).update({ customRoleName: "FAW Analyst" });
    //         }
    //         if (user.customRoleName === "Coach") {
    //             ref.child(user.key).update({ customRoleName: "FAW Coach" });
    //         }
    //         if (user.customRoleName === "Support Staff") {
    //             ref.child(user.key).update({
    //                 customRoleName: "FAW Support Staff",
    //             });
    //         }
    //         if (user.customRoleName === "Coach Educator") {
    //             ref.child(user.key).update({
    //                 customRoleName: "FAW Coach Educator",
    //             });
    //         }
    //         if (user.customRoleName === "Coach Ed Student") {
    //             ref.child(user.key).update({
    //                 customRoleName: "FAW Coach Ed Student",
    //             });
    //         }
    //         if (user.customRoleName === "Player") {
    //             ref.child(user.key).update({ customRoleName: "FAW Player" });
    //         }
    //         if (user.customRoleName === "Parent") {
    //             ref.child(user.key).update({ customRoleName: "FAW Parent" });
    //         }
    //     });
    // }

    // function convertTimelineNotes() {
    //     console.clear();
    //     console.log("Converting Timeline Notes");
    //     let ref = firebase.database().ref("timelines");

    //     timelines.forEach((timeline) => {
    //         // console.log(timeline);
    //         // console.log("Instances:");

    //         timeline.instances.forEach((instance) => {
    //             // console.log(instance);

    //             if (
    //                 instance.note &&
    //                 instance.note !== "" &&
    //                 instance.notes.length === 0
    //             ) {
    //                 var noteJSON = jsonFromUserEnteredStringArray([
    //                     instance.note,
    //                 ]);

    //                 ref.child(timeline.key)
    //                     .child("instances")
    //                     .child(instance.key)
    //                     .child("notes")
    //                     .set(noteJSON);
    //             }
    //         });
    //     });

    //     console.log("Done with Notes");
    // }

    // function convertTimelineLabels() {
    //     console.clear();
    //     console.log("Converting Timeline Labels");
    //     let ref = firebase.database().ref("timelines");

    //     timelines.forEach((timeline) => {
    //         // console.log(timeline);
    //         // console.log("Instances:");

    //         timeline.instances.forEach((instance) => {
    //             // console.log(instance);

    //             if (instance.labels && instance.labels.length !== 0) {
    //                 // console.log("OLD:");
    //                 // console.log(instance.labels);
    //                 // console.log(instance.labels);
    //                 if (instance.labels[0].text === null) {
    //                     console.log("CONVERTING");
    //                     let newLabels = [];

    //                     // console.log("NEW:");
    //                     instance.labels.forEach((labelText) => {
    //                         newLabels.push({ text: labelText });
    //                     });

    //                     // console.log(newLabels);
    //                     var labelsJSON =
    //                         jsonFromUserEnteredStringArray(newLabels);

    //                     // console.log("JSON:");
    //                     // console.log(labelsJSON);
    //                     ref.child(timeline.key)
    //                         .child("instances")
    //                         .child(instance.key)
    //                         .child("labels")
    //                         .set(labelsJSON);

    //                     // console.log("-----");
    //                 }
    //             }
    //         });
    //     });

    //     console.log("Done with Labels");
    // }

    // function removeOldNotifications() {
    //     console.clear();

    //     let ref = firebase.database().ref("notifications");

    //     let dateThreshold = 365; // Delete notifications older than this (days)
    //     let today = dayjs();

    //     var oldCount = 0;
    //     var newCount = 0;
    //     notifications.forEach((notification) => {
    //         let notificationDate = dayjs(notification.date);

    //         console.log(notification.date);

    //         let diff = today.diff(notificationDate, "days");
    //         // let diff = notificationDate.diff(today, "days");

    //         if (diff > dateThreshold) {
    //             oldCount += 1;
    //             ref.child(notification.key).remove();
    //         } else {
    //             newCount += 1;
    //         }
    //         console.log(diff);
    //         console.log("-------");
    //     });

    //     console.log("COUNTS:");
    //     console.log(notifications.length);
    //     console.log("OLD:");
    //     console.log(oldCount);
    //     console.log("NEW:");
    //     console.log(newCount);
    // }

    // function createNewTimelineInfoObjects() {
    //     console.clear();

    //     let ref = firebase.database().ref("timelineinfo");

    //     timelines.forEach((timeline) => {
    //         // console.log(timeline.name);

    //         var lastOpened = "";
    //         var created = "";

    //         if (timeline.lastOpened) {
    //             lastOpened = timeline.lastOpened;
    //         }

    //         if (timeline.created) {
    //             created = timeline.created;
    //         }

    //         let timelineJSON = {
    //             name: timeline.name,
    //             owner: timeline.owner,
    //             lastOpened: lastOpened,
    //             created: created,
    //             video: timeline.video,
    //             users: jsonFromArray(timeline.users),
    //             groups: jsonFromArray(timeline.groups),
    //         };

    //         ref.child(timeline.key).set(timelineJSON);
    //     });

    //     console.log("Finished Creating Timeline Info Objects");
    // }

    // function createNewPlaylistInfoObjects() {
    //     console.clear();

    //     let ref = firebase.database().ref("playlistinfo");

    //     playlists.forEach((playlist) => {
    //         // console.log(timeline.name);

    //         var lastOpened = "";
    //         var created = "";

    //         if (playlist.lastOpened) {
    //             lastOpened = playlist.lastOpened;
    //         }

    //         if (playlist.created) {
    //             created = playlist.created;
    //         }

    //         let playlistJSON = {
    //             name: playlist.name,
    //             owner: playlist.owner,
    //             lastOpened: lastOpened,
    //             created: created,
    //             video: playlist.video,
    //             users: jsonFromArray(playlist.users),
    //             groups: jsonFromArray(playlist.groups),
    //         };

    //         ref.child(playlist.key).set(playlistJSON);
    //     });

    //     console.log("Finished Creating Playlist Info Objects");
    // }
    // Test Change 4

    // function updateNotifications() {
    //     // Adds the hubName property to all notifications
    //     console.clear();
    //     console.log("Updating Notifications");
    //     let ref = firebase.database().ref("notifications");
    //     notifications.forEach((n) => {
    //         if (n.hubName === undefined) {
    //             let matchingFile = files.filter((f) => f.key === n.file)[0];

    //             if (matchingFile !== undefined) {
    //                 // console.log(matchingFile);
    //                 let ancestors = getAncestorHubs(
    //                     matchingFile,
    //                     null,
    //                     folders,
    //                     currentUserData
    //                 );

    //                 let firstAncestor = ancestors.reverse()[0];

    //                 if (firstAncestor !== undefined) {
    //                     ref.child(n.key).update({
    //                         hubName: firstAncestor.name,
    //                     });
    //                 }
    //             }
    //         }
    //     });

    //     console.log("Done");
    // }

    return (
        <>
            <Container fluid className={classes.root}>
                <Col>
                    <Row>
                        <AvatarUploadButton
                            recipient={currentUserData}
                            didChangeAvatar={didChangeAvatar}
                            path="users"
                        />
                    </Row>
                    <Row>
                        <TextField
                            id="firstName"
                            label="First Name"
                            variant="outlined"
                            value={firstName}
                            onChange={handleChange}
                            className={classes.inputMargin}
                        />
                        <TextField
                            id="lastName"
                            label="Last Name"
                            variant="outlined"
                            value={lastName}
                            onChange={handleChange}
                            className={classes.inputMargin}
                        />
                    </Row>
                    <Row>
                        <TextField
                            id="phone"
                            label="Mobile Number"
                            variant="outlined"
                            value={phone}
                            onChange={handleChange}
                            className={classes.inputMargin}
                            fullWidth
                        />
                    </Row>
                    <Row>
                        <TextField
                            id="email"
                            label="E-mail"
                            variant="outlined"
                            value={email}
                            onChange={handleChange}
                            className={classes.inputMargin}
                            fullWidth
                            disabled
                        />
                    </Row>
                    <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePressUpdateEmailButton}
                            className={classes.button}
                        >
                            Update Email
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePressUpdatePasswordButton}
                            className={classes.button}
                        >
                            Update Password
                        </Button>
                    </Row>
                    {/* <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={updateNotifications}
                            className={classes.button}
                        >
                            Update Notifications
                        </Button>
                    </Row> */}
                    {/* <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={updateUserTypes}
                            className={classes.button}
                        >
                            Update User Types
                        </Button>
                    </Row> */}
                    {/* <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={updateAllUsersGroups}
                            className={classes.button}
                        >
                            Update All Users Groups
                        </Button>
                    </Row> */}
                    {/* <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={checkAllUsersGroups}
                            className={classes.button}
                        >
                            Check All Users Groups
                        </Button>
                    </Row> */}
                    {/* <Row>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={checkGroups}
                            className={classes.button}
                        >
                            Check Groups
                        </Button>
                    </Row> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={changePermissions}
                        className={classes.button}
                    >
                        Change Permissions
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={updateHubs}
                        className={classes.button}
                    >
                        Update Hubs
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={removeFileUsage}
                        className={classes.button}
                    >
                        Remove File Usage
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={createLastLogin}
                        className={classes.button}
                    >
                        Create Last Login
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={makeHubPublic}
                        className={classes.button}
                    >
                        Make Hub Public
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={updatePermissions}
                        className={classes.button}
                    >
                        Update Permissions
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={convertTimelineNotes}
                        className={classes.button}
                    >
                        Convert Timeline Notes
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={convertTimelineLabels}
                        className={classes.button}
                    >
                        Convert Timeline Labels
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={removeOldNotifications}
                        className={classes.button}
                    >
                        Remove Old Notifications
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={createNewTimelineInfoObjects}
                        className={classes.button}
                    >
                        Create New Timeline Objects
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={createNewPlaylistInfoObjects}
                        className={classes.button}
                    >
                        Create New Playlist Objects
                    </Button> */}
                </Col>
            </Container>
            <FloatingSaveButton onClick={handleSaveClick} />
            {isInProgress && (
                <CircularProgress
                    size={56}
                    className={classes.buttonProgress}
                />
            )}
            <ToolbarWithLogo />
            <Snackbar
                open={statusMessageIsVisible}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            <ChangePasswordDialog
                open={changePasswordDialogIsOpen}
                onClose={handleCloseChangePasswordDialog}
                onSuccess={showPasswordChangeStatus}
            />
            <ChangeEmailDialog
                open={changeEmailDialogIsOpen}
                onClose={handleCloseChangeEmailDialog}
                onSuccess={showEmailChangeStatus}
            />
        </>
    );
}
