import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import FileDownload from "js-file-download";
import { useSnackbar } from "notistack";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dayjs from "dayjs";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import Tooltip from "@material-ui/core/Tooltip";

// My Files
import { DatabaseContext, UserContext } from "../../../../context";
import BackButton from "../../../BackButton";
import firebase from "../../../../firebase";
import CommentSection from "../CommentSection";
import ToolbarWithLogo from "../../../ToolbarWithLogo";
import NoPermissionsMessage from "../../../NoPermissionsMessage";
import FileEventsDialog from "../FileEventsDialog";
import EditFileDialog from "../EditFileDialog";
import FileUsageDialog from "../FileUsageDialog";

import {
    shouldFileBeVisible,
    doesFileHaveDuplicate,
} from "../../../../utility";

const useStyles = makeStyles((theme) => ({
    root: { paddingTop: 15, paddingBottom: 80 },
    // leftColumn: { width: "50%" },
    document: {
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
    },
    page: {
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        paddingLeft: 15,
    },
    displayName: {
        flexGrow: 1,
        paddingTop: 5,
    },
    commentsDisabled: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
    },
    menuButton: {
        width: 55,
        height: 55,
    },
    fullScreenButton: {
        width: 55,
        height: 55,
    },
    uploadedByText: {
        color: "grey",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AudioViewerPage(props) {
    const location = useLocation();
    const classes = useStyles();
    const history = useHistory();

    const [folder, setFolder] = useState();
    const [file, setFile] = useState();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [anchorEl, setAnchorEl] = useState(null);

    const { files, comments, notifications, events, users, folders, groups } =
        useContext(DatabaseContext);

    const [userHasPermission, setUserHasPermission] = useState();
    const [userIsManager, setUserIsManager] = useState(false);

    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
        useState(false);

    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [usageDialogOpen, setUsageDialogOpen] = useState(false);

    const { currentUserData } = useContext(UserContext);

    let downloadProgress = 0;

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");

    const [uploadedBy, setUploadedBy] = useState("");

    const [hasLoggedUsage, setHasLoggedUsage] = useState(false);

    const [isInFavourites, setIsInFavourites] = useState(false);

    const action = (key) => {
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress
                    variant="determinate"
                    value={downloadProgress}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="inherit"
                    >{`${Math.round(downloadProgress)}%`}</Typography>
                </Box>
            </Box>
        );
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        let currentFileKey = location.pathname.replace("/audio/", "");

        let file = files.filter((f) => f.key === currentFileKey)[0];

        if (file && currentUserData) {
            setFile(file);

            let folder = folders.filter((f) => f.key === file.folder)[0];

            if (folder) {
                setFolder(folder);
            }

            if (shouldFileBeVisible(file, groups, currentUserData)) {
                setUserHasPermission(true);
            } else {
                setUserHasPermission(false);
            }

            if (currentUserData.faveFiles) {
                if (currentUserData.faveFiles.includes(file.key)) {
                    setIsInFavourites(true);
                } else {
                    setIsInFavourites(false);
                }
            } else {
                setIsInFavourites(false);
            }

            setUserIsManager(
                file.managers.includes(currentUserData.key) ||
                    currentUserData.role === "System Digital Manager" ||
                    file.uploadedBy === currentUserData.key
            );

            if (file.uploadedBy) {
                let uploadedByUser = users.filter(
                    (u) => u.key === file.uploadedBy
                )[0];
                if (uploadedByUser) {
                    setUploadedBy(
                        uploadedByUser.firstName + " " + uploadedByUser.lastName
                    );
                }
            }

            // Record File Access
            if (!hasLoggedUsage) {
                console.log("Log File Usage");

                let ref = firebase.database().ref("fileusage");

                ref.push({
                    file: file.key,
                    fileName: file.displayName,
                    fileType: file.type,
                    user: currentUserData.key,
                    action: "Open",
                    date: dayjs().toString(),
                });

                setHasLoggedUsage(true);
            }

            // // Record File Access
            // let fileUsageRef = firebase.database().ref("fileusage");
            // fileUsageRef.push({
            //     file: file.key,
            //     user: currentUserData.key,
            //     opened: dayjs().toString(),
            // });
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [location.file, files, location.pathname, history, currentUserData]);

    function handleEventDialogOpen() {
        setEventDialogOpen(true);
    }

    function handleCloseEventDialog() {
        setEventDialogOpen(false);
    }

    function handleDownloadClick() {
        if (file) {
            enqueueSnackbar("Downloading: " + file.filename, {
                variant: "default",
                action,
                persist: true,
                key: file.key,
            });

            axios({
                url: file.url,
                method: "GET",
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.floor(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );

                    downloadProgress = percentCompleted;

                    enqueueSnackbar("Downloading: " + file.filename, {
                        variant: "default",
                        action,
                        persist: true,
                        key: file.key,
                        preventDuplicate: true,
                    });
                },
            }).then((response) => {
                FileDownload(response.data, file.filename);

                // Record that the user downloaded the file

                let ref = firebase.database().ref("fileusage");

                ref.push({
                    file: file.key,
                    fileName: file.displayName,
                    fileType: file.type,
                    user: currentUserData.key,
                    action: "Download",
                    date: dayjs().toString(),
                });

                //

                downloadProgress = 0;
                closeSnackbar(file.key);
            });
            handleMenuClose();
        }
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.target);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function handleDeleteClick() {
        setAnchorEl(null);
        setConfirmDeleteDialogIsOpen(true);
    }

    function handleConfirmDeleteDialogClose() {
        setConfirmDeleteDialogIsOpen(false);
    }

    function handleConfirmDeletePressed() {
        if (userIsManager) {
            // Delete the comments
            comments.forEach((comment) => {
                if (comment.file === file.key) {
                    let commentRef = firebase
                        .database()
                        .ref("comments")
                        .child(comment.key);
                    commentRef.update({ deleted: true });
                }
            });

            // Delete any notifications for the file
            notifications.forEach((notification) => {
                if (notification.file === file.key) {
                    let notificationRef = firebase
                        .database()
                        .ref("notifications")
                        .child(notification.key);
                    notificationRef.remove();
                }
            });

            // // Delete the file in any events

            events.forEach((event) => {
                let filesToKeepArray = [];
                let needsChange = false;
                event.files.forEach((aFile) => {
                    if (aFile.key === file.key) {
                        needsChange = true;
                    } else {
                        filesToKeepArray.push(aFile.key);
                    }
                });

                if (needsChange) {
                    let eventRef = firebase
                        .database()
                        .ref("events")
                        .child(event.key);

                    // Change remaining files to JSON
                    let filesObject = {};

                    filesToKeepArray.forEach((aFile) => {
                        filesObject[aFile] = true;
                    });

                    eventRef.update({ files: filesObject });
                }
            });

            // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
            users.forEach((user) => {
                let filesToKeepArray = [];
                let needsChange = false;
                user.files.forEach((aFile) => {
                    if (aFile === file.key) {
                        needsChange = true;
                    } else {
                        filesToKeepArray.push(aFile);
                    }
                });

                if (needsChange) {
                    let userRef = firebase
                        .database()
                        .ref("users")
                        .child(user.key);

                    // Change remaining files to JSON
                    let filesObject = {};

                    filesToKeepArray.forEach((aFile) => {
                        filesObject[aFile] = true;
                    });

                    userRef.update({ files: filesObject });
                }
            });

            // Check if the file has duplicates, still in the database, if there are no duplicates then delete the file and thumbnail from storage
            if (!doesFileHaveDuplicate(file, files)) {
                // Delete the file from firebase storage
                console.log("Deleting From Storage");

                var fileKey = file.key;
                if (file.original) {
                    // If the file has an original property, this is the key of the original file,
                    // and therefore you need to use this key to remove the file from storage
                    fileKey = file.original;
                }

                let storageRef = firebase
                    .storage()
                    .ref("files")
                    .child(fileKey)
                    .child(file.filename);
                storageRef.delete();
            }

            // Delete the file
            let fileRef = firebase.database().ref("files").child(file.key);

            fileRef.remove();

            history.goBack();
        }

        setConfirmDeleteDialogIsOpen(false);
    }

    function handleEditClick() {
        setAnchorEl(null);
        setEditDialogOpen(true);
    }

    function handleUsageClick() {
        setAnchorEl(null);
        setUsageDialogOpen(true);
    }

    function handleCloseEditDialog(outcome) {
        if (outcome === "saved") {
            showStatusMessage("File edited successfully", "success");
        }

        setEditDialogOpen(false);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function handleCloseUsageDialog() {
        setUsageDialogOpen(false);
    }

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    function handleAddToFavouritesPress() {
        // Get the users current favourite files
        let favesObject = {};

        currentUserData.faveFiles.forEach((faveFile) => {
            favesObject[faveFile] = true;
        });

        // Add the current file

        favesObject[file.key] = true;

        // Update firebase

        let ref = firebase.database().ref("users").child(currentUserData.key);
        ref.update({ faveFiles: favesObject });
    }

    function handleRemoveFromFavouritesPress() {
        // Get the users current favourite files
        let favesObject = {};

        currentUserData.faveFiles.forEach((faveFile) => {
            if (faveFile !== file.key) {
                favesObject[faveFile] = true;
            }
        });

        // Update firebase

        let ref = firebase.database().ref("users").child(currentUserData.key);
        ref.update({ faveFiles: favesObject });
    }

    return (
        <>
            {userHasPermission && (
                <>
                    <BackButton />

                    <Container fluid className={classes.root}>
                        {file && (
                            <>
                                <Col sm={6}>
                                    <Row>
                                        <AudioPlayer
                                            autoPlay={false}
                                            src={file.url}
                                        />
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h2 className={classes.displayName}>
                                                {file.displayName}
                                            </h2>
                                            {uploadedBy !== "" && (
                                                <span
                                                    className={
                                                        classes.uploadedByText
                                                    }
                                                >
                                                    Uploaded By: {uploadedBy}
                                                </span>
                                            )}
                                        </Col>

                                        <IconButton
                                            onClick={handleEventDialogOpen}
                                            className={classes.menuButton}
                                            aria-label="moreVideoEditOptions"
                                        >
                                            <EventNoteIcon />
                                        </IconButton>
                                        {isInFavourites ? (
                                            <Tooltip title="Remove From Favourites">
                                                <IconButton
                                                    onClick={
                                                        handleRemoveFromFavouritesPress
                                                    }
                                                    className={
                                                        classes.menuButton
                                                    }
                                                    aria-label="moreVideoEditOptions"
                                                >
                                                    <StarIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Add to Favourites">
                                                <IconButton
                                                    onClick={
                                                        handleAddToFavouritesPress
                                                    }
                                                    className={
                                                        classes.menuButton
                                                    }
                                                    aria-label="moreVideoEditOptions"
                                                >
                                                    <StarBorderIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <IconButton
                                            onClick={handleMenuOpen}
                                            className={classes.menuButton}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </Row>
                                    <Divider />
                                    <Row>
                                        {file.allowComments ? (
                                            <CommentSection file={file} />
                                        ) : (
                                            <span
                                                className={
                                                    classes.commentsDisabled
                                                }
                                            >
                                                Comments are disabled for this
                                                file
                                            </span>
                                        )}
                                    </Row>
                                </Col>
                            </>
                        )}
                    </Container>
                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {userIsManager && (
                            <MenuItem onClick={handleEditClick}>
                                Edit File
                            </MenuItem>
                        )}
                        {userIsManager && (
                            <MenuItem onClick={handleDeleteClick}>
                                Delete File
                            </MenuItem>
                        )}
                        {userIsManager && (
                            <MenuItem onClick={handleUsageClick}>
                                File Usage Data
                            </MenuItem>
                        )}
                        <MenuItem
                            disabled={!file.allowDownloads}
                            onClick={handleDownloadClick}
                        >
                            Download File
                        </MenuItem>
                    </Menu>
                </>
            )}
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            {confirmDeleteDialogIsOpen && (
                <Dialog
                    open={confirmDeleteDialogIsOpen}
                    onClose={handleConfirmDeleteDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Delete File
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this file?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleConfirmDeleteDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeletePressed}
                            color="primary"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {userHasPermission === false && <NoPermissionsMessage />}
            <ToolbarWithLogo />
            {eventDialogOpen && (
                <FileEventsDialog
                    open={eventDialogOpen}
                    onClose={handleCloseEventDialog}
                    file={file}
                />
            )}
            {editDialogOpen && (
                <EditFileDialog
                    open={editDialogOpen}
                    onClose={handleCloseEditDialog}
                    file={file}
                    folder={folder}
                />
            )}
            {usageDialogOpen && (
                <FileUsageDialog
                    open={usageDialogOpen}
                    onClose={handleCloseUsageDialog}
                    file={file}
                />
            )}
        </>
    );
}
