import React, { useState, useContext, useEffect } from "react";
// import dayjs from "dayjs";

import { Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// My Files
import { UserContext, DatabaseContext } from "../../../../context";
import firebase from "../../../../firebase";
import { jsonFromArray, doesFileHaveDuplicate } from "../../../../utility";
import ShareFileDialog from "../ShareFileDialog";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingRight: 10,
    },
    label: {
        textAlign: "left",
        cursor: "pointer",
        marginTop: 15,
        marginLeft: 10,
        width: "100%",
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    listItem: {
        backgroundColor: "rgb(245, 245, 245)",
        padding: 5,
        height: 60,
        borderRadius: 5,
        margin: 5,
    },
}));

export default function PDFListItem(props) {
    const classes = useStyles();
    const history = useHistory();

    const { currentUserData } = useContext(UserContext);

    const [clickLocation, setClickLocation] = useState({
        mouseX: null,
        mouseY: null,
    });

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);
    const [fileNameDialogOpen, setFileNameDialogOpen] = useState(false);
    const [newFileName, setNewFileName] = useState("");

    const [isSelected, setIsSelected] = useState(false); // Folder is selected when user right clicks on it
    const [shareFileDialogOpen, setShareFileDialogOpen] = useState(false);

    const { comments, notifications, events, users, files } =
        useContext(DatabaseContext);

    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [userCanShare, setUserCanShare] = useState(false);
    const [fileAllowsSharing, setFileAllowsSharing] = useState(false);

    useEffect(() => {
        setNewFileName(props.file.displayName);
        if (currentUserData) {
            if (
                props.folder.managers.includes(currentUserData.key) ||
                currentUserData.role === "System Digital Manager" ||
                props.file.uploadedBy === currentUserData.key
            ) {
                setUserIsAdmin(true);
            } else {
                setUserIsAdmin(false);
            }

            if (currentUserData.permissions.shareFiles) {
                setUserCanShare(true);
            } else {
                setUserCanShare(false);
            }

            if (props.file.allowSharing) {
                setFileAllowsSharing(true);
            } else {
                setFileAllowsSharing(false);
            }
        }

        // Special Case for favourite files folder
        if (props.folder.id === "faves_fggh2973jgbvbiu") {
            setUserIsAdmin(false);
        }
    }, [props, currentUserData]);

    function handleClick() {
        // // Record that the user opened the file

        // let ref = firebase.database().ref("fileusage");

        // ref.push({
        //     file: props.file.key,
        //     fileName: props.file.displayName,
        //     fileType: props.file.type,
        //     user: currentUserData.key,
        //     action: "Open",
        //     date: dayjs().toString(),
        // });

        // Go to the file page

        history.push({
            pathname: `/pdf/${props.file.key}`,
            file: props.file,
            folder: props.folder,
        });
    }

    function handleRightClick(event) {
        event.preventDefault();

        // Checks that the current user is a manager for the folder or hub
        if (userIsAdmin) {
            setIsSelected(true);

            setClickLocation({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });
        }
    }

    const handleMenuClose = (event) => {
        if (event.target.id === "renamefile") {
            setFileNameDialogOpen(true);
        } else if (event.target.id === "deletefile") {
            setConfirmDeleteDialogOpen(true);
        } else if (event.target.id === "duplicatefile") {
            createDuplicateFile();
        } else if (event.target.id === "sharefile") {
            setShareFileDialogOpen(true);
        }

        setClickLocation({
            mouseX: null,
            mouseY: null,
        });

        setIsSelected(false);
    };

    const handleKeyboardInput = (e) => {
        setNewFileName(e.target.value);
    };

    const handleFileRenameDialogClose = (key) => () => {
        setFileNameDialogOpen(false);

        if (key === "OK") {
            if (userIsAdmin) {
                let ref = firebase
                    .database()
                    .ref("files")
                    .child(props.file.key);
                ref.update({ displayName: newFileName });

                props.file.displayName = newFileName;
            }
        } else {
            setNewFileName(props.file.displayName);
        }
    };

    const handleConfirmDeleteDialogClose = (key) => () => {
        setConfirmDeleteDialogOpen(false);

        if (key === "OK") {
            if (userIsAdmin) {
                deleteFile();
            }
        }
    };

    function createDuplicateFile() {
        // creates a new entry for the file in the database, but it references the same file in storage
        var filesRef = firebase.database().ref("files");

        var original = props.file.key;

        if (props.file.original) {
            // Creating a duplicate of a duplicate, so the original should be the key of the original file
            original = props.file.original;
        }

        var uploadedBy = "";
        if (currentUserData) {
            uploadedBy = currentUserData.key;
        }

        filesRef.push({
            filename: props.file.filename,
            displayName: props.file.displayName + " Copy",
            folder: props.file.folder,
            type: props.file.type,
            allowDownloads: props.file.allowDownloads,
            allowComments: props.file.allowComments,
            uploadedBy: uploadedBy,
            managers: jsonFromArray(props.file.managers),
            groups: jsonFromArray(props.file.groups),
            ancestors: jsonFromArray(props.file.ancestors),
            url: props.file.url,
            thumbnail: props.file.thumbnail,
            original: original,
        });
    }

    async function deleteFile() {
        // Delete the comments
        comments.forEach((comment) => {
            if (comment.file === props.file.key) {
                let commentRef = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);
                commentRef.update({ deleted: true });
            }
        });

        // Delete any notifications for the file
        notifications.forEach((notification) => {
            if (notification.file === props.file.key) {
                let notificationRef = firebase
                    .database()
                    .ref("notifications")
                    .child(notification.key);
                notificationRef.remove();
            }
        });

        // // Delete the file in any events

        events.forEach((event) => {
            let filesToKeepArray = [];
            let needsChange = false;
            event.files.forEach((aFile) => {
                if (aFile.key === props.file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile.key);
                }
            });

            if (needsChange) {
                let eventRef = firebase
                    .database()
                    .ref("events")
                    .child(event.key);

                // Change remaining files to JSON
                let filesObject = {};

                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });

                eventRef.update({ files: filesObject });
            }
        });

        // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
        users.forEach((user) => {
            let filesToKeepArray = [];
            let needsChange = false;
            user.files.forEach((aFile) => {
                if (aFile === props.file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile);
                }
            });

            if (needsChange) {
                let userRef = firebase.database().ref("users").child(user.key);

                // Change remaining files to JSON
                let filesObject = {};

                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });

                userRef.update({ files: filesObject });
            }
        });

        // Check if the file has duplicates, still in the database, if there are no duplicates then delete the file and thumbnail from storage

        if (!doesFileHaveDuplicate(props.file, files)) {
            // Delete the file from firebase storage
            console.log("Deleting From Storage");

            var fileKey = props.file.key;
            if (props.file.original) {
                // If the file has an original property, this is the key of the original file,
                // and therefore you need to use this key to remove the file from storage
                fileKey = props.file.original;
            }

            let storageRef = firebase
                .storage()
                .ref("files")
                .child(fileKey)
                .child(props.file.filename);
            storageRef.delete();

            // Delete the thumbnail from firebase storage
            let thumbnailStorageRef = firebase
                .storage()
                .ref("files")
                .child(fileKey)
                .child("thumbnail");
            thumbnailStorageRef.delete();
        }

        // Delete the file
        let fileRef = firebase.database().ref("files").child(props.file.key);

        fileRef.remove();

        props.showStatusMessage("File deleted successfully", "success");
    }

    function handleCloseShareFileDialog() {
        setShareFileDialogOpen(false);
    }

    return (
        <Draggable
            draggableId={props.file.key}
            index={props.index}
            isDragDisabled={
                currentUserData.role === "Player" ||
                currentUserData.role === "FAW Player" ||
                currentUserData.role === "Club Player" ||
                currentUserData.role === "Coach Ed Student" ||
                currentUserData.role === "FAW Coach Ed Student" ||
                currentUserData.role === "Club Coach Ed Student" ||
                currentUserData.role === "FAW Parent" ||
                currentUserData.role === "Club Parent" ||
                currentUserData.role === "Referee"
            }
        >
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classes.container}
                    ref={provided.innerRef}
                    onContextMenu={handleRightClick}
                >
                    <ListItem
                        onClick={handleClick}
                        className={classes.listItem}
                        style={
                            isSelected
                                ? { border: "2px solid rgb(0, 149, 61)" }
                                : {}
                        }
                    >
                        <Avatar variant="rounded" src={props.file.thumbnail}>
                            <PictureAsPdfIcon />
                        </Avatar>
                        <p className={classes.label}>
                            {props.file.displayName}
                        </p>
                    </ListItem>
                    <Menu
                        keepMounted
                        open={clickLocation.mouseY !== null}
                        onClose={handleMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            clickLocation.mouseY !== null &&
                            clickLocation.mouseX !== null
                                ? {
                                      top: clickLocation.mouseY,
                                      left: clickLocation.mouseX,
                                  }
                                : undefined
                        }
                    >
                        <div>
                            {userCanShare && fileAllowsSharing && (
                                <MenuItem
                                    id="sharefile"
                                    onClick={handleMenuClose}
                                >
                                    Share to Hub
                                </MenuItem>
                            )}
                            <MenuItem id="renamefile" onClick={handleMenuClose}>
                                Rename
                            </MenuItem>
                            <MenuItem
                                id="duplicatefile"
                                onClick={handleMenuClose}
                            >
                                Duplicate
                            </MenuItem>
                            <MenuItem id="deletefile" onClick={handleMenuClose}>
                                Delete
                            </MenuItem>
                        </div>
                    </Menu>
                    <Dialog
                        open={fileNameDialogOpen}
                        onClose={handleFileRenameDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Rename File
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter a new name for the file.
                            </DialogContentText>
                            <TextField
                                value={newFileName}
                                onChange={handleKeyboardInput}
                                autoFocus
                                margin="dense"
                                id="name"
                                label=""
                                type=""
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleFileRenameDialogClose("Cancel")}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleFileRenameDialogClose("OK")}
                                color="primary"
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={confirmDeleteDialogOpen}
                        onClose={handleConfirmDeleteDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Delete File
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this file?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleConfirmDeleteDialogClose(
                                    "Cancel"
                                )}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleConfirmDeleteDialogClose("OK")}
                                color="primary"
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {shareFileDialogOpen && (
                        <ShareFileDialog
                            open={shareFileDialogOpen}
                            onClose={handleCloseShareFileDialog}
                            file={props.file}
                        />
                    )}
                </div>
            )}
        </Draggable>
    );
}
