import React, { useEffect, useState, useContext } from "react";
import firebase from "./firebase";
import dayjs from "dayjs";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null); // Firebase Auth User Object

    useEffect(() => {
        firebase.auth().onAuthStateChanged(setCurrentUser);
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
    const [currentUserData, setCurrentUserData] = useState(null); // User object from the database

    const { users } = useContext(DatabaseContext);

    useEffect(() => {
        if (firebase.auth().currentUser) {
            console.log("Setting Current User Data");
            let user = users.filter(
                (u) => u.uid === firebase.auth().currentUser.uid
            )[0];

            if (user) {
                setCurrentUserData(user);

                console.log("Updating Last Login");
                // Set most recent login time for user
                let ref = firebase.database().ref("userlogin");

                ref.child(user.key).update({
                    lastLogin: dayjs().toString(),
                });
            }
        }
    }, [users]);

    return (
        <UserContext.Provider value={{ currentUserData }}>
            {children}
        </UserContext.Provider>
    );
};

export const DatabaseContext = React.createContext();

export const DatabaseProvider = ({ children }) => {
    const { currentUser } = useContext(AuthContext);

    const [users, setUsers] = useState([]);
    const [userLogins, setUserLogins] = useState([]);
    const [userConsent, setUserConsent] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [comments, setComments] = useState([]);
    const [events, setEvents] = useState([]);
    const [calendars, setCalendars] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [fileUsage, setFileUsage] = useState([]);
    const [groups, setGroups] = useState([]);
    const [playlistInfo, setPlaylistInfo] = useState([]);
    const [playlistUsage, setPlaylistUsage] = useState([]);
    const [timelineInfo, setTimelineInfo] = useState([]);
    const [surveyInfo, setSurveyInfo] = useState([]);

    // This is only used in ArchiveProgressDialog.jsx
    const [playlists, setPlaylists] = useState([]);

    // This is only used in ArchiveProgressDialog.jsx
    const [timelines, setTimelines] = useState([]);

    useEffect(() => {
        if (currentUser) {
            // getCurrentUserData();
            getUserConsent();
            getUsers();
            getUserLogins();
            getNotifications();
            getFolders();
            getGroups();
            getFiles();
            getComments();
            getEvents();
            getCalendars();
            getConversations();
            getPlaylistInfo();
            getPlaylistUsage();
            getTimelineInfo();
            getSurveyInfo();

            // getFileUsage();
            // getTimelines();
            // getPlaylists();
        } else {
            getUserConsent();
        }
    }, [currentUser]);

    function getUserConsent() {
        console.log("Getting User consent from Firebase");
        let ref = firebase.database().ref("userconsent");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                array.push({
                    key: key,
                    uid: data.uid,
                    name: data.name,
                    hasConsent: data.hasConsent,
                });
            });

            setUserConsent(array);
        });
    }

    // function getCurrentUserData() {
    //     console.log("Getting Current User Data from Firebase");

    //     let uid = firebase.auth().currentUser.uid;
    //     let ref = firebase.database().ref("users").child(uid);

    //     ref.on("value", function (snapshot) {
    //         let array = [];

    //         let key = snapshot.key;
    //         let data = snapshot.val();

    //         let userFiles = [];
    //         if (data.files) {
    //             let keys = Object.keys(data.files);

    //             keys.forEach((key) => {
    //                 userFiles.push(key);
    //             });
    //         }

    //         let userAdmins = [];
    //         if (data.admins) {
    //             let keys = Object.keys(data.admins);

    //             keys.forEach((key) => {
    //                 userAdmins.push(key);
    //             });
    //         }

    //         array.push({
    //             key: key,
    //             uid: data.uid,
    //             firstName: data.firstName,
    //             lastName: data.lastName,
    //             fullName: data.firstName + " " + data.lastName,
    //             email: data.email,
    //             phone: data.phone,
    //             parentFirstName: data.parentFirstName,
    //             parentLastName: data.parentLastName,
    //             parentEmail: data.parentEmail,
    //             parentPhone: data.parentPhone,
    //             customRoleName: data.customRoleName,
    //             permissions: data.permissions,
    //             imageURL: data.imageURL,
    //             role: data.role,
    //             under18: data.under18,
    //             createdBy: data.createdBy,
    //             admins: userAdmins,
    //             files: userFiles,
    //         });

    //         setUsers(array);
    //     });
    // }

    function getUsers() {
        console.log("Getting Users from Firebase");
        let ref = firebase.database().ref("users");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                let userFiles = [];
                if (data.files) {
                    let keys = Object.keys(data.files);

                    keys.forEach((key) => {
                        userFiles.push(key);
                    });
                }

                let userAdmins = [];
                if (data.admins) {
                    let keys = Object.keys(data.admins);

                    keys.forEach((key) => {
                        userAdmins.push(key);
                    });
                }

                let userFaveFiles = [];
                if (data.faveFiles) {
                    let keys = Object.keys(data.faveFiles);

                    keys.forEach((key) => {
                        userFaveFiles.push(key);
                    });
                }

                array.push({
                    key: key,
                    uid: data.uid,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    fullName: data.firstName + " " + data.lastName,
                    email: data.email,
                    phone: data.phone,
                    parentFirstName: data.parentFirstName,
                    parentLastName: data.parentLastName,
                    parentEmail: data.parentEmail,
                    parentPhone: data.parentPhone,
                    customRoleName: data.customRoleName,
                    permissions: data.permissions,
                    imageURL: data.imageURL,
                    role: data.role,
                    under18: data.under18,
                    createdBy: data.createdBy,
                    admins: userAdmins,
                    files: userFiles,
                    faveFiles: userFaveFiles,
                });
            });

            setUsers(array);
        });
    }

    function getUserLogins() {
        console.log("Getting User Logins from Firebase");
        let ref = firebase.database().ref("userlogin");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                array.push({
                    key: key,
                    lastLogin: data.lastLogin,
                });
            });

            setUserLogins(array);
        });
    }

    function getNotifications() {
        console.log("Getting Notifications from Firebase");
        let ref = firebase.database().ref("notifications");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                let notificationUsers = [];
                if (data.users) {
                    let keys = Object.keys(data.users);

                    keys.forEach((key) => {
                        notificationUsers.push(key);
                    });
                }

                let notificationManagers = [];
                if (data.managers) {
                    let keys = Object.keys(data.managers);

                    keys.forEach((key) => {
                        notificationManagers.push(key);
                    });
                }

                let notificationSeenBy = []; // Array of users that have either clicked on or dismissed the notification
                if (data.seenBy) {
                    let keys = Object.keys(data.seenBy);

                    keys.forEach((key) => {
                        notificationSeenBy.push(key);
                    });
                }

                array.push({
                    key: key,
                    users: notificationUsers,
                    managers: notificationManagers,
                    seenBy: notificationSeenBy,
                    file: data.file,
                    type: data.type,
                    title: data.title,
                    message: data.message,
                    url: data.url,
                    thumbnail: data.thumbnail,
                    date: data.date,
                    fromDate: data.fromDate,
                    hubName: data.hubName,
                });
            });

            setNotifications(array);
        });
    }

    // function getHubs() {
    //     console.log("Getting Hubs from Firebase");
    //     let ref = firebase.database().ref("hubs");

    //     ref.on("value", function (snapshot) {
    //         let array = [];

    //         snapshot.forEach(function (childSnapshot) {
    //             let key = childSnapshot.key;
    //             let data = childSnapshot.val();

    //             let hubFolders = [];
    //             if (data.folders) {
    //                 let folderKeys = Object.keys(data.folders);

    //                 for (let i = 0; i < folderKeys.length; i++) {
    //                     hubFolders.push(folderKeys[i]);
    //                 }
    //             }

    //             let hubUsers = [];
    //             if (data.users) {
    //                 let userKeys = Object.keys(data.users);

    //                 for (let i = 0; i < userKeys.length; i++) {
    //                     hubUsers.push(userKeys[i]);
    //                 }
    //             }

    //             let ancestorHubs = [];
    //             if (data.ancestorHubs) {
    //                 let hubKeys = Object.keys(data.ancestorHubs);

    //                 for (let i = 0; i < hubKeys.length; i++) {
    //                     ancestorHubs.push(hubKeys[i]);
    //                 }
    //             }

    //             array.push({
    //                 key: key,
    //                 name: data.name,
    //                 parent: data.parent,
    //                 children: data.children,
    //                 managers: data.managers,
    //                 level: data.level,
    //                 users: hubUsers,
    //                 folders: hubFolders,
    //                 ancestorHubs: ancestorHubs,
    //             });
    //         });

    //         setHubs(array);
    //     });
    // }

    function getFiles() {
        console.log("Getting Files from Firebase");

        let ref = firebase.database().ref("files");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                let ancestors = [];
                if (data.ancestors) {
                    let hubKeys = Object.keys(data.ancestors);

                    for (let i = 0; i < hubKeys.length; i++) {
                        ancestors.push(hubKeys[i]);
                    }
                }

                let events = [];
                if (data.events) {
                    // Convert events from a JSON object to an array
                    let eventKeys = Object.keys(data.events);

                    for (let i = 0; i < eventKeys.length; i++) {
                        events.push({
                            key: eventKeys[i],
                        });
                    }
                }

                // let fileUsers = [];
                // if (data.users) {
                //     let userKeys = Object.keys(data.users);

                //     for (let i = 0; i < userKeys.length; i++) {
                //         fileUsers.push(userKeys[i]);
                //     }
                // }

                let fileManagers = [];
                if (data.managers) {
                    let managerKeys = Object.keys(data.managers);

                    for (let i = 0; i < managerKeys.length; i++) {
                        fileManagers.push(managerKeys[i]);
                    }
                }

                let fileGroups = [];
                if (data.groups) {
                    let groupKeys = Object.keys(data.groups);

                    for (let i = 0; i < groupKeys.length; i++) {
                        fileGroups.push(groupKeys[i]);
                    }
                }

                // let fileDuplicates = [];
                // if (data.duplicates) {
                //     let duplicateKeys = Object.keys(data.duplicates);

                //     for (let i = 0; i < duplicateKeys.length; i++) {
                //         fileDuplicates.push(duplicateKeys[i]);
                //     }
                // }

                let file = {
                    key: key,
                    filename: data.filename,
                    displayName: data.displayName,
                    folder: data.folder,
                    type: data.type,
                    allowDownloads: data.allowDownloads,
                    allowComments: data.allowComments,
                    allowSharing: data.allowSharing,
                    url: data.url,
                    thumbnail: data.thumbnail,
                    original: data.original,
                    uploadedBy: data.uploadedBy,
                    events: events,
                    // users: fileUsers,
                    managers: fileManagers,
                    groups: fileGroups,
                    ancestors: ancestors,
                };

                array.push(file);
            });

            setFiles(array);
        });
    }

    function getFolders() {
        console.log("Getting Folders from Firebase");

        let ref = firebase.database().ref("folders");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                let ancestors = [];
                if (data.ancestors) {
                    let hubKeys = Object.keys(data.ancestors);

                    for (let i = 0; i < hubKeys.length; i++) {
                        ancestors.push(hubKeys[i]);
                    }
                }

                let folderManagers = [];
                if (data.managers) {
                    let managerKeys = Object.keys(data.managers);

                    for (let i = 0; i < managerKeys.length; i++) {
                        folderManagers.push(managerKeys[i]);
                    }
                }

                let folderUsers = [];
                if (data.users) {
                    let userKeys = Object.keys(data.users);

                    for (let i = 0; i < userKeys.length; i++) {
                        folderUsers.push(userKeys[i]);
                    }
                }

                // If you change something here, also change in FileNavigatorPage useEffect, where clone folders are created
                array.push({
                    key: key,
                    id: key,
                    name: data.name,
                    hub: data.hub,
                    parent: data.parent,
                    imageURL: data.imageURL,
                    createdBy: data.createdBy,
                    parentNode: {},
                    childNodes: [],
                    managers: folderManagers,
                    users: folderUsers,
                    ancestors: ancestors,
                });
            });

            setFolders(array);
        });
    }

    function getGroups() {
        console.log("Getting Groups from Firebase");
        let ref = firebase.database().ref("groups");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                let groupUsers = [];
                if (data.users) {
                    let keys = Object.keys(data.users);

                    keys.forEach((key) => {
                        groupUsers.push({ key: key });
                    });
                }

                array.push({
                    key: key,
                    name: data.name,
                    hub: data.hub,
                    users: groupUsers,
                });
            });

            setGroups(array);
        });
    }

    function getComments() {
        console.log("Getting Comments from Firebase");
        let ref = firebase.database().ref("comments");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                array.push({
                    key: key,
                    text: data.text,
                    file: data.file,
                    user: data.user,
                    date: data.date,
                    deleted: data.deleted,
                    parent: data.parent,
                    author: data.author,
                });
            });

            setComments(array);
        });
    }

    function getEvents() {
        console.log("Getting Events from Firebase");
        let ref = firebase.database().ref("events");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                // Convert files from a JSON object to an array

                let eventFiles = [];
                if (data.files) {
                    let fileKeys = Object.keys(data.files);

                    for (let i = 0; i < fileKeys.length; i++) {
                        eventFiles.push({
                            key: fileKeys[i],
                        });
                    }
                }

                array.push({
                    key: key,
                    date: data.date,
                    name: data.name,
                    color: "#" + data.color,
                    calendar: data.calendar,
                    files: eventFiles,
                });
            });

            setEvents(array);
        });
    }

    function getFileUsage() {
        console.log("Getting File Usage from Firebase");
        let ref = firebase.database().ref("fileusage");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let fileUsageKey = childSnapshot.key;
                let fileUsageData = childSnapshot.val();

                let usersSharedWith = [];

                if (fileUsageData.usersSharedWith) {
                    Object.keys(fileUsageData.usersSharedWith).forEach(
                        (key) => {
                            usersSharedWith.push({
                                key: key,
                            });
                        }
                    );
                }

                let groupsSharedWith = [];

                if (fileUsageData.groupsSharedWith) {
                    Object.keys(fileUsageData.groupsSharedWith).forEach(
                        (key) => {
                            groupsSharedWith.push({
                                key: key,
                            });
                        }
                    );
                }

                array.push({
                    key: fileUsageKey,
                    file: fileUsageData.file,
                    fileName: fileUsageData.fileName,
                    fileType: fileUsageData.fileType,
                    user: fileUsageData.user,
                    action: fileUsageData.action,
                    date: fileUsageData.date,
                    usersSharedWith: usersSharedWith,
                    groupsSharedWith: groupsSharedWith,
                });
            });

            setFileUsage(array);
        });
    }

    function getCalendars() {
        console.log("Getting Calendars from Firebase");
        let ref = firebase.database().ref("calendars");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let calendarKey = childSnapshot.key;
                let calendarData = childSnapshot.val();

                let calendarEvents = [];

                if (calendarData.events) {
                    Object.keys(calendarData.events).forEach((eventKey) => {
                        calendarEvents.push({
                            key: eventKey,
                        });
                    });
                }
                array.push({
                    key: calendarKey,
                    name: calendarData.name,
                    hub: calendarData.hub,
                    user: calendarData.user,
                    events: calendarEvents,
                });
            });

            setCalendars(array);
        });
    }

    function getConversations() {
        console.log("Getting Conversations from Firebase");
        let ref = firebase.database().ref("conversations");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                let messages = [];

                if (data.messages) {
                    // Turn messages from a JSON object to an array

                    let messageKeys = Object.keys(data.messages);
                    let messageValues = Object.values(data.messages);

                    for (let i = 0; i < messageKeys.length; i++) {
                        let readByObject = messageValues[i].readBy;

                        let readByArray = [];
                        if (readByObject) {
                            let readByIds = Object.keys(readByObject);

                            readByArray = readByIds;
                        }

                        messages.push({
                            key: messageKeys[i],
                            date: messageValues[i].date,
                            message: messageValues[i].message,
                            deleted: messageValues[i].deleted,
                            user: messageValues[i].user,
                            fullName: messageValues[i].fullName,
                            read: messageValues[i].read,
                            readBy: readByArray,
                        });
                    }
                }

                // Turn participants from a JSON object to an array

                let participants = [];
                let participantKeys = Object.keys(data.participants);

                for (let i = 0; i < participantKeys.length; i++) {
                    participants.push({
                        key: participantKeys[i],
                    });
                }

                let conversation = {
                    key: key,
                    participants: participants,
                    messages: messages,
                    lastMessage: data.lastMessage,
                    title: data.title,
                    deleted: data.deleted,
                    imageURL: data.imageURL,
                };

                array.push(conversation);
            });

            setConversations(array);
        });
    }

    function getPlaylistInfo() {
        console.log("Getting Playlist Info from Firebase");

        let ref = firebase.database().ref("playlistinfo");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                // Groups
                let playlistGroups = [];
                if (data.groups) {
                    let groupKeys = Object.keys(data.groups);

                    for (let i = 0; i < groupKeys.length; i++) {
                        playlistGroups.push(groupKeys[i]);
                    }
                }

                // Users
                let playlistUsers = [];
                if (data.users) {
                    let userKeys = Object.keys(data.users);

                    for (let i = 0; i < userKeys.length; i++) {
                        playlistUsers.push(userKeys[i]);
                    }
                }

                let playlist = {
                    key: key,
                    name: data.name,
                    lastOpened: data.lastOpened,
                    created: data.created,
                    owner: data.owner,
                    video: data.video,
                    users: playlistUsers,
                    groups: playlistGroups,
                };

                array.push(playlist);
            });

            setPlaylistInfo(array);
        });
    }

    // This is only used in ArchiveProgressDialog.jsx
    function getPlaylists(refresh) {
        return new Promise((resolve, reject) => {
            if (!refresh) {
                if (timelines.length > 0) {
                    console.log("Timelines Already Downloaded");
                    resolve(timelines);
                    return;
                }
            }

            console.log("Getting Playlists from Firebase");
            let ref = firebase.database().ref("playlists");

            ref.on("value", function (snapshot) {
                let array = [];

                snapshot.forEach(function (childSnapshot) {
                    let key = childSnapshot.key;
                    let data = childSnapshot.val();

                    let clips = [];

                    if (data.clips) {
                        // Turn messages from a JSON object to an array

                        let clipKeys = Object.keys(data.clips);
                        let clipValues = Object.values(data.clips);

                        for (let i = 0; i < clipKeys.length; i++) {
                            let clipLabels = [];
                            if (clipValues[i].labels) {
                                // Change to Object.values!
                                let labelTextValues = Object.values(
                                    clipValues[i].labels
                                );

                                for (
                                    let i = 0;
                                    i < labelTextValues.length;
                                    i++
                                ) {
                                    clipLabels.push(labelTextValues[i]);
                                }
                            }

                            clips.push({
                                key: clipKeys[i],
                                index: clipValues[i].index,
                                inTime: clipValues[i].inTime,
                                outTime: clipValues[i].outTime,
                                name: clipValues[i].name,
                                note: clipValues[i].note,
                                labels: clipLabels,
                                stars: clipValues[i].stars,
                                video: clipValues[i].video,
                            });
                        }
                    }

                    // Groups
                    let playlistGroups = [];
                    if (data.groups) {
                        let groupKeys = Object.keys(data.groups);

                        for (let i = 0; i < groupKeys.length; i++) {
                            playlistGroups.push(groupKeys[i]);
                        }
                    }

                    // Users
                    let playlistUsers = [];
                    if (data.users) {
                        let userKeys = Object.keys(data.users);

                        for (let i = 0; i < userKeys.length; i++) {
                            playlistUsers.push(userKeys[i]);
                        }
                    }

                    let playlist = {
                        key: key,
                        clips: clips,
                        name: data.name,
                        lastOpened: data.lastOpened,
                        created: data.created,
                        owner: data.owner,
                        video: data.video,
                        users: playlistUsers,
                        groups: playlistGroups,
                    };

                    array.push(playlist);
                });

                setPlaylists(array);
                resolve(array);
            });
        });
    }

    function getPlaylistUsage() {
        console.log("Getting Playlist Usage from Firebase");
        let ref = firebase.database().ref("playlistusage");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let playlistUsageKey = childSnapshot.key;
                let playlistUsageData = childSnapshot.val();

                array.push({
                    key: playlistUsageKey,
                    playlist: playlistUsageData.playlist,
                    user: playlistUsageData.user,
                    lastOpened: playlistUsageData.lastOpened,
                });
            });

            setPlaylistUsage(array);
        });
    }

    function getSurveyInfo() {
        console.log("Getting SurveyInfo from Firebase");

        let ref = firebase.database().ref("surveyinfo");

        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                // Groups
                let surveyGroups = [];
                if (data.groups) {
                    let groupKeys = Object.keys(data.groups);
                    let groupValues = Object.values(data.groups);

                    for (let i = 0; i < groupKeys.length; i++) {
                        surveyGroups.push({
                            key: groupKeys[i],
                            canEdit: groupValues[i].canEdit,
                            canViewResults: groupValues[i].canViewResults,
                        });
                    }
                }

                // Users
                let surveyUsers = [];
                if (data.users) {
                    let userKeys = Object.keys(data.users);
                    let userValues = Object.values(data.users);

                    for (let i = 0; i < userKeys.length; i++) {
                        surveyUsers.push({
                            key: userKeys[i],
                            canEdit: userValues[i].canEdit,
                            canViewResults: userValues[i].canViewResults,
                        });
                    }
                }

                let survey = {
                    key: key,
                    title: data.title,
                    intro: data.intro,
                    createdBy: data.createdBy,
                    createdOn: data.createdOn,
                    lastUpdated: data.lastUpdated,
                    file: data.file,
                    anonymous: data.anonymous,
                    groups: surveyGroups,
                    users: surveyUsers,
                };

                array.push(survey);
            });

            setSurveyInfo(array);
        });
    }

    function getTimelineInfo() {
        console.log("Getting TimelineInfo from Firebase");

        let ref = firebase.database().ref("timelineinfo");
        ref.on("value", function (snapshot) {
            let array = [];

            snapshot.forEach(function (childSnapshot) {
                let key = childSnapshot.key;
                let data = childSnapshot.val();

                // Groups
                let timelineGroups = [];
                if (data.groups) {
                    let groupKeys = Object.keys(data.groups);

                    for (let i = 0; i < groupKeys.length; i++) {
                        timelineGroups.push(groupKeys[i]);
                    }
                }

                // Users
                let timelineUsers = [];
                if (data.users) {
                    let userKeys = Object.keys(data.users);

                    for (let i = 0; i < userKeys.length; i++) {
                        timelineUsers.push(userKeys[i]);
                    }
                }

                let timeline = {
                    key: key,
                    name: data.name,
                    owner: data.owner,
                    lastOpened: data.lastOpened,
                    created: data.created,
                    video: data.video,
                    users: timelineUsers,
                    groups: timelineGroups,
                };

                array.push(timeline);
            });

            setTimelineInfo(array);
        });
    }

    // This is only used in ArchiveProgressDialog.jsx
    function getTimelines(refresh) {
        return new Promise((resolve, reject) => {
            if (!refresh) {
                if (timelines.length > 0) {
                    console.log("Timelines Already Downloaded");
                    resolve(timelines);
                    return;
                }
            }

            console.log("Getting Timelines from Firebase");

            let ref = firebase.database().ref("timelines");
            ref.on("value", function (snapshot) {
                let array = [];

                snapshot.forEach(function (childSnapshot) {
                    let key = childSnapshot.key;
                    let data = childSnapshot.val();

                    let instances = [];

                    if (data.instances) {
                        // Turn messages from a JSON object to an array

                        let clipKeys = Object.keys(data.instances);
                        let clipValues = Object.values(data.instances);

                        for (let i = 0; i < clipKeys.length; i++) {
                            // Labels
                            let instanceLabels = [];
                            if (clipValues[i].labels) {
                                // Change to Object.values!
                                let labelTextValues = Object.values(
                                    clipValues[i].labels
                                );

                                for (
                                    let i = 0;
                                    i < labelTextValues.length;
                                    i++
                                ) {
                                    instanceLabels.push(labelTextValues[i]);
                                }
                            }

                            // Notes
                            let instanceNotes = [];
                            if (clipValues[i].notes) {
                                let noteTextValues = Object.values(
                                    clipValues[i].notes
                                );

                                for (
                                    let i = 0;
                                    i < noteTextValues.length;
                                    i++
                                ) {
                                    instanceNotes.push(noteTextValues[i]);
                                }
                            }

                            instances.push({
                                key: clipKeys[i],
                                index: clipValues[i].index,
                                name: clipValues[i].name,
                                labels: instanceLabels,
                                notes: instanceNotes,
                                note: clipValues[i].note,
                                stars: clipValues[i].stars,
                                inTime: clipValues[i].inTime,
                                outTime: clipValues[i].outTime,
                            });
                        }
                    }

                    // Groups
                    let timelineGroups = [];
                    if (data.groups) {
                        let groupKeys = Object.keys(data.groups);

                        for (let i = 0; i < groupKeys.length; i++) {
                            timelineGroups.push(groupKeys[i]);
                        }
                    }

                    // Users
                    let timelineUsers = [];
                    if (data.users) {
                        let userKeys = Object.keys(data.users);

                        for (let i = 0; i < userKeys.length; i++) {
                            timelineUsers.push(userKeys[i]);
                        }
                    }

                    let timeline = {
                        key: key,
                        instances: instances,
                        name: data.name,
                        owner: data.owner,
                        lastOpened: data.lastOpened,
                        created: data.created,
                        video: data.video,
                        users: timelineUsers,
                        groups: timelineGroups,
                    };

                    array.push(timeline);
                });

                setTimelines(array);
                resolve(array);
            });
        });
    }

    return (
        <DatabaseContext.Provider
            value={{
                userConsent,
                users,
                userLogins,
                notifications,
                files,
                folders,
                comments,
                events,
                calendars,
                conversations,
                fileUsage,
                getFileUsage,
                groups,
                playlists,
                playlistInfo,
                playlistUsage,
                getPlaylists,
                timelines,
                timelineInfo,
                getTimelines,
                surveyInfo,
            }}
        >
            {children}
        </DatabaseContext.Provider>
    );
};
