import React, { useEffect, useState } from "react";
// import "eazychart-css"; // You just need to import this once.

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// My Files
import { makeid } from "../../../utility";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: 30,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        // width: window.innerWidth,
        overflow: "scroll",
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
    },
}));

export default function SurveyResultsFeed(props) {
    const classes = useStyles();
    const [responses, setResponses] = useState();

    useEffect(() => {
        setResponses(props.responses);
    }, [props]);

    return <Container className={classes.pageContainer}></Container>;
}
