import { useState, useContext } from "react";
// import dayjs from "dayjs";
import firebase from "../../firebase";
import { useHistory, useLocation } from "react-router-dom";

// Material UI Components
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CircularProgress } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// My Files
import { DatabaseContext } from "../../context";
import ResetPasswordDialog from "./ResetPasswordDialog";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    center: {
        display: "flex",
        justifyContent: "center",
    },
    alert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignInPage(props) {
    // firebase.auth().signOut();

    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();
    const { userConsent } = useContext(DatabaseContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isInProgress, setIsInProgress] = useState(false);
    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");

    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] =
        useState(false);

    function handleClick(event) {
        event.preventDefault();

        setIsInProgress(true);

        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;

                let matchingDBUser = userConsent.filter(
                    (u) => u.uid === user.uid
                )[0];

                if (matchingDBUser) {
                    if (matchingDBUser.hasConsent) {
                        console.log("Signed In: " + user.email);

                        // // Add login time to user in database
                        // let ref = firebase.database().ref("users");

                        // ref.child(matchingDBUser.key).update({
                        //     lastLogin: dayjs().toString(),
                        // });

                        const urlParams = new URLSearchParams(location.search);

                        const linkURL = urlParams.get("url");

                        if (linkURL) {
                            history.replace(linkURL);
                        } else {
                            history.replace("");
                        }
                    } else {
                        if (matchingDBUser.under18) {
                            setStatusMessage(
                                "Your Parent, Carer or Guardian has to give consent before you can sign in. They should have recieved an email with a link to the consent form."
                            );
                        } else {
                            setStatusMessage(
                                "You have to give consent before you can sign in, You should have recieved an email with a link to the consent form."
                            );
                        }

                        firebase.auth().signOut();
                        setStatusIsVisible(true);
                    }
                } else {
                    setStatusMessage(
                        "This account has been deactivated or the email address has been changed"
                    );
                    setStatusIsVisible(true);
                }

                setIsInProgress(false);
            })
            .catch((error) => {
                setIsInProgress(false);
                setEmail("");
                setPassword("");
                setStatusMessage(error.message);
                setStatusIsVisible(true);
            });
    }

    function handleTextInput(event) {
        if (event.target.id === "email") {
            setEmail(event.target.value);
        } else if (event.target.id === "password") {
            setPassword(event.target.value);
        }
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setStatusIsVisible(false);
    };

    function handleForgotPasswordClick(event) {
        event.preventDefault();
        setResetPasswordDialogOpen(true);
    }

    function handleCloseResetPasswordDialog() {
        setResetPasswordDialogOpen(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar
                    className={classes.avatar}
                    variant="rounded"
                    alt="Nodiant Logo"
                    src="images/logo512.png"
                />
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleClick}
                >
                    <TextField
                        value={email}
                        onChange={handleTextInput}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        value={password}
                        onChange={handleTextInput}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Typography className={classes.root}>
                        <Link
                            href="#"
                            onClick={handleForgotPasswordClick}
                            variant="body2"
                        >
                            Forgot Password
                        </Link>
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>

                    {isInProgress && (
                        <div className={classes.center}>
                            <CircularProgress />
                        </div>
                    )}
                </form>
                <div className={classes.alert}>
                    <Snackbar
                        open={statusIsVisible}
                        autoHideDuration={6000}
                        onClose={handleStatusClose}
                    >
                        <Alert onClose={handleStatusClose} severity="error">
                            {statusMessage}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            <ResetPasswordDialog
                open={resetPasswordDialogOpen}
                onClose={handleCloseResetPasswordDialog}
                email={email}
            />
        </Container>
    );
}
